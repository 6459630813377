import { mapState, mapGetters, mapActions } from 'vuex'
import store from './store/store.js'
import unloadConfirm from 'components/unload_confirm.vue'

export const toast = {
    methods: {
        toast(message, type = 'normal') {
            store.dispatch('setToast', {
                type,
                message,
            })
        },
    },
}

export const pageUnloadController = {
    data() {
        return {
            leaveConfirmationAction: false,
            changed: null,
            leaveActions: [
                {
                    display: 'Stay on page',
                    run: () => {
                        this.leaveConfirmationAction = false
                    },
                },
                {
                    display: 'Leave',
                    run: () => {
                        this.leaveConfirmationAction()
                    },
                },
            ],
        }
    },
    components: {
        unloadConfirm,
    },
    methods: {
        handleChange() {
            if (this.changed === null) {
                this.changed = false
            } else {
                this.changed = true
            }
        },
        handleLeaveModalExit() {
            this.leaveConfirmationAction = false
        },
    },
    beforeRouteLeave(to, from, next) {
        if (this.changed) {
            this.leaveConfirmationAction = next
        } else {
            next()
        }
    },
}

export const storeRefreshController = {
    data() {
        return {
            watcher: false,
            refreshAction: () => {
                this.handleRefresh()
            },
        }
    },
    computed: {
        refreshActive() {
            if (this.storeReloadMap[this.$route.params.store] === undefined) return false
            return true
        },
        reloadProgress() {
            if (!this.refreshActive) {
                return false
            }
            let info = this.storeReloadMap[this.$route.params.store]
            return `${info.numberOfRecordsLoaded / info.numberOfRecordsToLoad}`
        },
        lastRefreshed() {
            return dateStringToDate(this.store.storePOSRefreshDateTimeUTC)
        },
        ...mapGetters([
            'pos', 'storeReloadMap', 'store'
        ]),
    },
    methods: {
        handleRefresh() {
            if (this.storeReloadMap[this.$route.params.store] !== undefined) return
            this.toast('Starting menu refresh')

            this.reloadPos(this.$route.params.store)
                .then(() => {
                    this.initProgressWatcher()
                })
        },
        initProgressWatcher() {
            this.watcher = setInterval(() => {
                this.toast('Checking menu refresh progress')

                this.checkReloadProgress(this.$route.params.store)
                    .then(() => {
                        if (this.storeReloadMap[this.$route.params.store].requestComplete) {
                            clearInterval(this.watcher)
                            this.toast('Refreshed store', 'success')
                            this.getActiveStore(this.$route.params.store)
                            this.resetPosReload(this.$route.params.store)
                        }
                    })
            }, 15000)
        },
        ...mapActions([
            'getActiveStore',
            'reloadPos',
            'checkReloadProgress',
            'resetPosReload',
        ]),
    },
    watch: {
        pos() {
            clearInterval(this.watcher)

            if (this.pos !== 'grab') {
                if (this.storeReloadMap[this.$route.params.store] !== undefined && this.storeReloadMap[this.$route.params.store].reloadRequestID !== undefined) {
                    this.initProgressWatcher()
                }
            } else {
                this.resetPosReload()
            }
        },
    },
}

export const editWarningController = {
    data: () => ({
        warningActive: false,
        warningType: '',
        effectedItems: [],
        handleEditCancel: () => true,
        handleEditContinue: () => true,
    }),
    methods: {
        generateWarning(type, effectedItems, success) {
            if (!effectedItems.length) {
                success()

                return true
            }

            this.warningActive = true
            this.warningType = type
            this.effectedItems = effectedItems

            this.handleEditCancel = () => {
                this.warningActive = false
            }

            this.handleEditContinue = () => {
                this.warningActive = false

                success()
            }
        },
    },
}

export const ProductNavigationController = {
    computed: {
        ...mapGetters({
            navigationProductCategories: 'productCategories',
        }),
    },
    methods: {
        hasNextItem(current) {
            return this.getNextItem(current)
        },
        hasPreviousItem(current) {
            return this.getPreviousItem(current)
        },

        gotoNextItem(current, editMode = false) {
            const to = editMode ? 'store-product-edit' : 'store-product-detail'

            const nextItem = this.getNextItem(current)

            if (!nextItem) {
                return this.toast('Can not navigate to the next item.')
            }

            this.$router.push({
                name: to,
                params: {
                    product: nextItem,
                },
            })
        },

        gotoPreviousItem(current, editMode = false) {
            const to = editMode ? 'store-product-edit' : 'store-product-detail'

            const previousItem = this.getPreviousItem(current)

            if (!previousItem) {
                return this.toast('Can not navigate to the previous item.')
            }

            this.$router.push({
                name: to,
                params: {
                    product: previousItem,
                },
            })
        },

        getPreviousItem(current) {
            const products = this.navigationProductCategories
                .reduce((sum, next) => {
                    return sum.concat(next.children)
                }, [])

            const currentIndex = products.findIndex(el => {
                return el.inventoryItemID === current
            })

            if ((currentIndex === -1) || (currentIndex - 1 == - 1)) {
                return false
            }

            return products[currentIndex - 1].inventoryItemID
        },

        getNextItem(current) {
            const products = this.navigationProductCategories
                .reduce((sum, next) => {
                    return sum.concat(next.children)
                }, [])

            const currentIndex = products.findIndex(el => {
                return el.inventoryItemID === current
            })

            if ((currentIndex === -1) || (products.length === currentIndex + 1)) {
                return false
            }

            return products[currentIndex + 1].inventoryItemID
        },
    },
}
