var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container-fluid main" }, [
    _c(
      "div",
      { staticClass: "row no-gutters" },
      [
        _c("div", { staticClass: "col" }, [_c("router-view")], 1),
        _c("toast"),
        _vm.loading ? _c("loading") : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }