<template>
    <div class="row no-gutters">
        <div class="alert-dialog col-12">
            <div 
                v-if="title || onExit" 
                class="alert-dialog__header row no-gutters">
                <div 
                    v-if="title" 
                    class="alert-dialog__title col-10">
                    {{ title }}
                </div>

                <div 
                    v-if="onExit" 
                    class="alert-dialog__exit col" 
                    @click="onExit">
                    <span class="icon icon--close"/>
                </div>
            </div>

            <div class="row no-gutters">
                <div class="col-12">
                    <div class="alert-dialog__body">
                        <slot/>
                    </div>
                </div>
            </div>

            <div 
                v-if="actions && actions.length" 
                class="alert-dialog__buttons row no-gutters">
                <action-button
                    v-for="action in actions"
                    :key="Math.random()"
                    :type="action.type"
                    :action="action.run"
                    class="col d-flex justify-content-center"
                >{{ action.display }}</action-button>
            </div>
        </div>
    </div>
</template>

<script>
import actionButton from './action_button.vue'

export default {
    name: 'DeleteConfirm',
    components: {
        actionButton,
    },
    props: {
        title: String,
        onExit: Function,
        actions: {
            type: Array,
        },
    },
}
</script>

<style lang="scss">
@import '~scss/variables';
@import '~scss/mixins';

.alert-dialog {
    $border: 1px solid $grey_light_1;

    &__header, &__body {
        border-bottom: $border;
    }

    &__header {
        padding: spacing(xs);
        padding-right: 0;
    }

    &__title {
        font-weight: 900;
    }

    &__exit {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    &__body {
        padding: spacing(sm);
    }

    &__buttons {
        height: height(10);
    }

    &__button {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: spacing(sm);
        border-right: $border;

        &:last-of-type {
            border-right: none;
        }

        &:hover {
            background-color: $grey_light_1;
        }
    }

    &__delete {
        color: $delete_red;
    }
}
</style>
