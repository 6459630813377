
const removeDuplicates = exports.removeDuplicates = (arr, cb) => {
    let values = []

    return arr.filter(el => {
        const value = cb(el)

        if (values.indexOf(value) !== -1) {
            return false
        }

        values.push(value)
        return true
    })
}
