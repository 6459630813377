const { removeDuplicates } = require('../helpers')
const modGroupsTransform = require('./mod_groups')

exports.commit = modSet => {
    const options = modSet.inventoryOptions
    const groups = removeDuplicates(options, el => el.optionGroupName)

    const children = groups.map(option => {
        const mods = options
            .filter(mod => (mod.optionGroupName === option.optionGroupName))

        return {
            title: option.optionGroupName,
            optionGroupName: option.optionGroupName,
            optionGroupAvailable: true,
            optionGroupStartTime: '1/1/1900 8:00 am',
            optionGroupEndTime: '1/1/1900 11:30 pm',
            optionGroupDisplayOrder: '1',
            optionGroupSelection: '0',
            pos_modifierGroupID: '', // May break something
            optionID: option.optionID,
            inventoryOptions: mods,
        }
    })
        .map(modGroupsTransform.commit)

    return Object.assign(
        modSet,
        {
            id: modSet.modSetID,
            title: modSet.modSetName,
            inventoryOptions: null,
            children,
        }
    )
}

exports.undo = modSet => {
    // transform back
}
