import idleJs from 'idle-js'
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            idle: false,
            tokenLife: 119 * 60000
        }
    },
    computed: {
        ...mapGetters([
            'user'
        ])
    },
    methods: {
        onActive() {
            this.checkUserAccessTokenLife()
        },
        checkUserAccessTokenLife() {
            if (new Date().getTime() - new Date(this.user.accessTokenCreated).getTime() > this.tokenLife) {

                if (this.user) 
                    this.toast('Your session has expired, please keep this in mind before attempting any modifications.', 'error')
                    
                this.expireAccessToken()
            }
        },
        ...mapActions([
            'expireAccessToken'
        ])
    },
    created() {
        this.idle = new idleJs({
            idle: 2.5 * 60000, // idle time in ms (2.5 minutes)
            events: ['mousemove', 'keydown', 'mousedown', 'touchstart'], // events that will trigger the idle resetter
            onIdle: () => {}, // callback function to be executed after idle time
            onActive: this.onActive, // callback function to be executed after back form idleness
            onHide: () => {}, // callback function to be executed when window become hidden
            onShow: () => {}, // callback function to be executed when window become visible
        }).start();
    }

}