import { authHttp, passthrough } from '../http'
const hubENV = process.env.VUE_APP_HUB_ENV;

export default {
    namespaced: true,
    state: {
        users: [],
        tmpStoreAccess: undefined
    },
    mutations: {
        RECIEVE_USERS: (state, users) => {
            state.users = users
        },
        SET_TMP_ACCESS: (state, access) => {
            state.tmpStoreAccess = access
        }
    },
    actions: {
        createUsers: ({ commit, state }, users) => {
            commit('SET_LOADING', true, { root: true })

            let request = CreateUserAccountsRequest(users)
            return passthrough(request).then(res => {
                commit('RECIEVE_USERS', res.data)

            }).finally(() => {
                commit('SET_LOADING', false, { root: true })

            })
        },
        updateUser: ({ commit, state }, user) => {
            commit('SET_LOADING', true, { root: true })

            let request = UpdateUserAccountRequest(user)
            return passthrough(request).then(res => {
                commit('RECIEVE_USERS', res.data)
            }).finally(() => {
                commit('SET_LOADING', false, { root: true })
            })
        },
        getUsers: ({ commit, state }) => {
            commit('SET_LOADING', true, { root: true })

            let request = GetUsersRequest()
            return passthrough(request).then(res => {
                commit('RECIEVE_USERS', res.data)
            }).finally(() => {
                commit('SET_LOADING', false, { root: true })
            })
        },
        deleteUser: ({ commit, state }, userID) => {
            commit('SET_LOADING', true, { root: true })

            let request = DeleteUserRequest(userID)
            return passthrough(request).then(res => {
                commit('RECIEVE_USERS', res.data)
            }).finally(() => {
                commit('SET_LOADING', false, { root: true })
            })
        },
        setPasswordForUser: ({ commit, state }, payload) => {
            commit('SET_LOADING', true, { root: true })

            let request = SetPasswordRequest(payload, hubENV)
            return passthrough(request).then(res => {
                return res.data
            }).finally(() => {
                commit('SET_LOADING', false, { root: true })
            })
        },
        sendUserInvitation: ({ commit, state }, user) => {
            commit('SET_LOADING', true, { root: true })
            let payload = {
                hubENV,
                ...user,
            }
            let request = SendInvitationRequest(payload)
            
            return passthrough(request).then(res => {
                return res.data
            }).finally(() => {
                commit('SET_LOADING', false, { root: true })
            })
        },
        sendPasswordReset: ({ commit }, email) => {
            commit('SET_LOADING', true, { root: true })

            let request = ResetPasswordRequest(email)
            
            return passthrough(request).then(res => {
                return res.data
            }).finally(() => {
                commit('SET_LOADING', false, { root: true })
            })
        },
        clearDuplicatedStoreAccess: ({commit}) => {
            commit('SET_TMP_ACCESS', undefined)
        },
        setDuplicatedStoreAccess: ({commit}, storeAccess) => {
            commit("SET_TMP_ACCESS", storeAccess)
        }
    },
    getters: {
        users: state => {
            return state.users
        },
        duplicatedStoreAccess: state => {
            return state.tmpStoreAccess
        }
    }
}

let CreateUserAccountsRequest = (accounts) => {
    let users = accounts.map(el => {
        return {
            ...el,
            hubENV,
        }
    })
    return {
        url: 'CursusHub_CreateUserAccounts',
        method: 'POST',
        body: users,
    }
}

let UpdateUserAccountRequest = (account) => {
    return {
        url: 'CursusHub_UpdateUserAccount',
        method: 'POST',
        body: account,
    }
}

let GetUsersRequest = () => {
    return {
        url: 'CursusHub_GetUserAccounts',
        method: 'GET',
    }
}

let DeleteUserRequest = (userID) => {
    return {
        url: 'CursusHub_DeleteUserAccount',
        method: 'GET',
        qs: {
            userID,
        }
    }

}

let SetPasswordRequest = (payload, hubENV) => {
    return {
        url: 'CursusHub_SetPasswordForUserAccount',
        method: 'POST',
        body: payload,
        qs: {
            hubENV,
        }
    }

}

let SendInvitationRequest = (payload) => {
    return {
        url: 'CursusHub_SendHubInvitationEmail',
        method: 'POST',
        body: payload,
    }

}

let ResetPasswordRequest = (email) => {
    return {
        url: 'CursusHub_ResetPasswordForUserAccount',
        method: 'GET',
        qs: {
            email,
            hubENV
        },
        rawQS: true,
    }
}
