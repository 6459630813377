var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "loading-appear" } }, [
    _c("div", { staticClass: "loading" }, [
      _c("div", { staticClass: "loading__indicator" }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }