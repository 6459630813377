var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "editing-modal" }, [
    _c(
      "div",
      { staticClass: "blur-filter" },
      [
        _c(
          "transition",
          { attrs: { name: "shift-fade", appear: "" } },
          [
            _c(
              "modal",
              { staticClass: "modal", class: [`modal--${_vm.size}`] },
              [
                _c(
                  "div",
                  { staticClass: "editing-modal__inner row no-gutters" },
                  [
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _vm._t("default", function () {
                          return [
                            _c(
                              "h1",
                              { staticClass: "block block-xs--xl-top" },
                              [_vm._v("Yield content")]
                            ),
                          ]
                        }),
                      ],
                      2
                    ),
                  ]
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }