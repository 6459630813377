const imageServer = process.env.VUE_APP_IMAGE_SERVER;
const productCategoryTransform = require('./product_category.js')
const modSetTransform = require('./mod_set.js')

exports.commit = location => {
    const categories = location.inventoryTitles
        .map(productCategoryTransform.commit(location.inventoryItemMains))

    const modSets = location.modSets
        .map(modSetTransform.commit)

    return {
        store: location,
        categories,
        modSets,
        imageServer,
    }
}

exports.undo = location => {
    // transform back
}
