import Vue from 'vue';
import { ItemType } from 'components/explorer_new/itemType';

export default {
    namespaced: true,
    state: {
        list: {},
        struct:{},
        editMode:false,
        isDragInProgress: {  },
        expanded: {
            [ItemType.Folder]: {
                allExpanded: false,
            },
            [ItemType.Subcategory]: {
                allExpanded: false,
            },
            // item is not expandable
        },
        selectedItemIds: {
            [ItemType.Item]: {},
            // folder and subcategories could not be selected
        },
        actionBindings: {
            [ItemType.Folder]: {},
            [ItemType.Subcategory]: {},
            [ItemType.Item]: {},
        },
        pendingChanges: {
            hasPendingChanges: false,
            hasRootSortUpdate: false,
            [ItemType.Folder]: {},
            [ItemType.Subcategory]: {},
            // item doesn't have children so couldn't have pending changes
        },
        pendingDelete: {
            [ItemType.Item]: {}
        }
    },
    mutations: {
        SET_LIST(state, list) {
            Vue.set(state, 'list', list);
        },
        SET_STRUCT(state, struct) {
            Vue.set(state, 'struct', struct);
        },
        SET_EDIT_MODE(state, editMode) {
            state.editMode = editMode;
        },
        SET_EXPANDED(state, {type, id, isExpanded}) {
            Vue.set(state.expanded[type], id,  isExpanded);
        },
        SET_EXPANDED_FOR_TYPES(state, {types, isExpanded}) {
            for (const type of types) {
                state.expanded[type] = {
                    allExpanded: isExpanded,
                };
            }
        },
        SET_SELECTED(state, {type, id, isSelected}) {
            Vue.set(state.selectedItemIds[type], id, isSelected);
        },
        RESET_SELECTED(state, {type}) {
            Vue.set(state.selectedItemIds, type, { });
        },
        SET_PENDING_CHANGES(state, parent) {
            state.pendingChanges.hasPendingChanges = true;

            if (!parent) {
                Vue.set(state.pendingChanges, 'hasRootSortUpdate', true);
            } else {
                Vue.set(state.pendingChanges[parent.type], parent.id, true);
            }
        },
        RESET_PENDING_CHANGES(state) {
            state.pendingChanges = {
                hasPendingChanges: false,
                hasRootSortUpdate: false,
                [ItemType.Folder]: {},
                [ItemType.Subcategory]: {},
            };
        },
        SET_PENDING_DELETE(state, {type, ids}) {
            Vue.set(state.pendingDelete, type, ids.reduce((acc,el) => ({...acc,  [el]: true}), { }));
        },
        INIT_EXPANDED(state, {type, ids, isExpanded}) {
            Vue.set(state.expanded, type, ids.reduce((acc, el) => ({
                ...acc,
                [el]: isExpanded
            }), {}));
        },
        SET_IS_DRAG_IN_PROGRESS(state, type) {
            state.isDragInProgress = type;
        }
    },
    actions: {
        initializeWithCategories({commit, state, dispatch}, categories) {
        },
        handleDragEnd({commit, state, dispatch}, { parent }) {
            commit("SET_PENDING_CHANGES", parent)
            commit("SET_IS_DRAG_IN_PROGRESS", false);
        },
        handleDragStart({commit}, {type}) {
            commit("SET_IS_DRAG_IN_PROGRESS", type);
            // expand all possible drop locations
            // set that drag in progress
        },
        reset({commit}){
            commit("RESET_SELECTED", { type: ItemType.Item });
            commit("RESET_PENDING_CHANGES");
            // TODO: Implement reset
        },
        toggleSelected({commit, state, dispatch}, {type, id}) {
            commit("SET_SELECTED", {type, id, isSelected: !state.selectedItemIds[type][id] })
        }
    },
    getters: {
        struct: state => state.struct,
        editMode: state => state.editMode,
        isSelected: state => (type ,id) => !!state.selectedItemIds[type][id],
        isExpanded: state => (type ,id) => (state.expanded[type].allExpanded && state.expanded[type][id] !== false) || !!state.expanded[type][id],
        pendingChanges: state => state.pendingChanges,
        hasPendingChanges: state => state.pendingChanges.hasPendingChanges,
        list: state => state.list,
        isDragInProgress: state => state.isDragInProgress,
        selectedItemIds: state => type => Object
            .entries(state.selectedItemIds[type])
            .filter(([,v]) => v)
            .map(([id,]) => id),
        isPendingDelete: state => (type,id) => !!state.pendingDelete[type][id],
    },
}
