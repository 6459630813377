export const SyncStatus = {
    Unscheduled: "UNSCHEDULED",
    Scheduled: "SCHEDULED",
    ScheduledWithWarning: "SCHEDULEDWITHWARNING",
    Failed: "FAILED",
    InProgress: "INPROGRESS",
    Completed: "COMPLETED"
}


