<template>
    <transition name="toast">
        <div 
            v-if="toastMessage.message && toastMessage.type" 
            :class="[`toast--${toastMessage.type}`]" 
            class="toast" 
            @click="clear">
            {{ toastMessage.message }}
        </div>
    </transition>
</template>
<script>
import { mapState, mapActions } from 'vuex'
export default {
    name: 'Toast',
    props: {
        timeout: {
            type: Number,
            default: 2500,
        },
    },
    data: () => ({
        toastTimeout: null,
    }),
    computed: mapState([
        'toastMessage',
    ]),
    watch: {
        toastMessage(n) {
            if (n.message && n.type) {
                this.initTimeout()
            }
        },
    },
    methods: {
        ...mapActions([
            'clearToast',
        ]),
        initTimeout() {
            clearTimeout(this.toastTimeout)
            this.toastTimeout = setTimeout(() => {
                this.clear()
            }, this.timeout)
        },
        clear() {
            clearTimeout(this.toastTimeout)
            this.clearToast()
        },
    },
    mounted() {
        this.initTimeout()
    },
}
</script>
<style lang="scss">
    @import '~scss/variables';
    @import '~scss/mixins';
    .toast {
        @include roboto-bold;
        @include depth;
        position: fixed;
        top: 50px;
        right: 50px;
        z-index: zi(toast);
        padding: 20px 24px;
        border-radius: 4px;
        font-size: fz(sm);
        cursor: pointer;
        line-height: 25px;
        background-color: #fff;
        &:before {
            content: '';
            background-position: center;
            background-repeat: no-repeat;
            display: inline-block;
            vertical-align: middle;
            margin-right: 20px;
        }

        &--normal {
            &:before {
                margin-right: 0;
            }
        }

        &--error {
            background-color: #f66;
            color: $white;
            &:before {
                background-image: url('~img/alert_white.svg');
                background-size: 23px 22px;
                width: 23px;
                height: 22px;
            }
        }
        &--success {
            background-color: #fffac6;
            color: #729809;
            &:before {
                background-image: url('~img/check_green.svg');
                background-size: 23px 20px;
                width: 23px;
                height: 20px;
            }
        }
    }
    .toast-enter-active, .toast-leave-active {
        transition: all $transition_short;
    }
    .toast-enter, .toast-leave-to {
        transform: translateX(50px);
        opacity: 0;
    }
</style>
