
const mapGeneralModFields = mod => {
    return Object.assign(mod, {
        groupName: mod.optionGroupName || mod.choiceGroupName,
        available: mod.optionAvailable || mod.choiceAvailable,
        cost: mod.optionCost || mod.choiceCost,
        title: mod.optionDescription || mod.choiceDescription,
        endTime: mod.optionEndTime || mod.choiceEndTime,
        startTime: mod.optionStartTime || mod.choiceStartTime,
        id: mod.optionID || mod.choiceID,
        order: mod.optionOrder || mod.choiceOrder,
    })
}

const removeSpecficModFields = mod => {
    delete mod.optionAvailable
    delete mod.choiceAvailable
    delete mod.optionCost
    delete mod.choiceCost
    delete mod.optionDescription
    delete mod.choiceDescription
    delete mod.optionEndTime
    delete mod.choiceEndTime
    delete mod.optionStartTime
    delete mod.choiceStartTime
    delete mod.optionID
    delete mod.choiceID
    delete mod.optionOrder
    delete mod.choiceOrder

    return mod
}

const mapOptionSpecificModFields = mod => {
    return Object.assign(mod, {
        optionGroupName: mod.groupName,
        optionAvailable: mod.available || true,
        optionCost: mod.cost,
        optionDescription: mod.title,
        optionEndTime: mod.endTime,
        optionStartTime: mod.startTime,
        optionID: mod.id,
        optionOrder: mod.order,
    })
}

const mapChoiceSpecificModFields = mod => {
    return Object.assign(mod, {
        choiceGroupName: mod.groupName,
        choiceAvailable: mod.available || true,
        choiceCost: mod.cost,
        choiceDescription: mod.title,
        choiceEndTime: mod.endTime,
        choiceStartTime: mod.startTime,
        choiceID: mod.id,
        choiceOrder: mod.order,
    })
}

const removeGeneralModFields = mod => {
    delete mod.groupName
    delete mod.available
    delete mod.cost
    delete mod.title
    delete mod.endTime
    delete mod.startTime
    delete mod.id
    delete mod.order

    return mod
}

exports.commit = mod => {
    const generalMod = removeSpecficModFields(mapGeneralModFields(mod))

    generalMod.pos_modifierID = generalMod.productID

    return generalMod
}

exports.revert = type => mod => {
    const isChoice = (type === 'choice')
    const resolvedMod = isChoice ? mapChoiceSpecificModFields(mod) : mapOptionSpecificModFields(mod)

    return removeGeneralModFields(
        Object.assign(
            resolvedMod,
            {
                pos_modifierID: resolvedMod.pos_modifierID || resolvedMod.productID,
            }
        )
    )
}
