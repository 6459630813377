import { passthrough } from '../http'
import _ from 'lodash'
import router from '../routes'
import { SyncStatus } from '../helpers/future_sync'
import Vue from 'vue'

const ScheduledDummy = {
    date: "2022-03-01",
    time: "12:00",
    delay: 6,
    status: SyncStatus.Scheduled
}

const InProgressDummy = {
    date: "2022-03-01",
    time: "12:00",
    delay: 6,
    status: SyncStatus.InProgress
}

const ScheduledDummyWarning = {
    date: "2022-03-01",
    time: "12:00",
    delay: 6,
    status: SyncStatus.ScheduledWithWarning,
    integrityCheckErrors: [{ "errors": ["All: SubSizesMissing"], "inventoryItemID": 871212, "inventoryItemName": "DoNotDeleteMeUntilMenuIsFinished" }, { "errors": ["All: SubSizesMissing"], "inventoryItemID": 871213, "inventoryItemName": "DoNotDeleteMeUntilMenuIsFinished" }, { "errors": ["All: SubSizesMissing"], "inventoryItemID": 871218, "inventoryItemName": "DoNotDeleteMeUntilMenuIsFinished" }, { "errors": ["All: SubSizesMissing"], "inventoryItemID": 871219, "inventoryItemName": "DoNotDeleteMeUntilMenuIsFinished" }]
}

const ScheduledDummyFailed = {
    date: "2022-01-01",
    time: "12:00",
    delay: 6,
    status: SyncStatus.Failed,
    integrityCheckErrors: [{ "errors": ["All: SubSizesMissing"], "inventoryItemID": 871212, "inventoryItemName": "DoNotDeleteMeUntilMenuIsFinished" }, { "errors": ["All: SubSizesMissing"], "inventoryItemID": 871213, "inventoryItemName": "DoNotDeleteMeUntilMenuIsFinished" }, { "errors": ["All: SubSizesMissing"], "inventoryItemID": 871218, "inventoryItemName": "DoNotDeleteMeUntilMenuIsFinished" }, { "errors": ["All: SubSizesMissing"], "inventoryItemID": 871219, "inventoryItemName": "DoNotDeleteMeUntilMenuIsFinished" }]
}

const defaultState = {
    status: SyncStatus.Unscheduled,
    time: undefined,
    date: undefined,
    delay: undefined
}

export default {
    namespaced: true,
    state: {
        syncStatus: defaultState
    },
    mutations: {
        RECEIEVE_SYNC_STATUS: (state, status) => {
            Vue.set(state, 'syncStatus', status)
        },
    },
    actions: {
        syncNow: ({ commit, state }, storeWaypointID) => {
            let request = SyncNowRequest(storeWaypointID)
            return passthrough(request).then(res => {
                commit('RECEIEVE_SYNC_STATUS', res.data)
                return true
            })
        },
        scheduleSync: ({ commit, state }, payload) => {
            let request = ScheduleMenuSyncRequest(payload)
            return passthrough(request).then(res => {
                commit('RECEIEVE_SYNC_STATUS', res.data)
                return true
            })
        },
        deleteSync: ({ commit, state }, storeWaypointID) => {
            let request = CancelMenuSyncRequest(storeWaypointID)
            return passthrough(request).then(res => {
                commit('RECEIEVE_SYNC_STATUS', res.data)
                return true
            })
        },
        getMenuSyncStatus: ({ commit, state }, storeWaypointID) => {
            let request = GetMenuSyncStatusRequest(storeWaypointID)
            return passthrough(request).then(res => {
                commit('RECEIEVE_SYNC_STATUS', res.data)
                return true
            })
        }
    },
    getters: {
        syncStatus: state => {
            return state.syncStatus
        },
    }
}

let GetMenuSyncStatusRequest = (storeWaypointID) => {
    return {
        url: 'CursusHub_GetMenuSyncStatus',
        method: 'GET',
        qs: {
            storeWaypointID,
        },
    }
}

let CancelMenuSyncRequest = (storeWaypointID) => {
    return {
        url: 'CursusHub_CancelMenuSync',
        method: 'GET',
        qs: {
            storeWaypointID,
        },
    }
}

let ScheduleMenuSyncRequest = (payload) => {
    return {
        url: 'CursusHub_ScheduleMenuSync',
        method: 'POST',
        qs: {
            storeWaypointID: payload.storeWaypointID
        },
        body: payload
    }
}

let SyncNowRequest = (storeWaypointID) => {
    return {
        url: 'CursusHub_SyncNow',
        method: 'GET',
        qs: {
            storeWaypointID,
        },
    }
}