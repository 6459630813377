<template>
    <div>
        <editing-modal 
            v-if="leaveAction" 
            size="sm">
            <alert-dialog
                :onExit="onExit"
                :actions="actions"
                title="Unsaved Options"
            >
                You have unsaved options. Are you sure you want to leave this page?
            </alert-dialog>
        </editing-modal>
    </div>
</template>

<script>
import editingModal from 'components/editing_modal.vue'
import alertDialog from 'components/alert_dialog.vue'

export default {
    components: {
        editingModal,
        alertDialog,
    },
    props: {
        onExit: {
            type: Function,
            required: true,
        },
        leaveAction: {
            type: [Function, Boolean],
            required: true,
        },
        actions: {
            type: Array,
            required: true,
        },
    },
}
</script>
