import {OperationStatus} from "helpers/common";
import {passthrough} from "@/http";
import {getLabelById} from "helpers";

export default {
    namespaced: true,
    state: {
        receiptTemplates: {
            status: OperationStatus.Unknown,
            errors: [],
            data: [],
        },
        templateMetadata: {
            status: OperationStatus.Unknown,
            errors: [],
            data: {
                orderStatuses: {},
                orderTypes: {},
                platformTypes: {},
            },
        },
    },
    mutations: {
        SET_RECEIPT_TEMPLATES_LOADING(state) {
            state.receiptTemplates.status = OperationStatus.InProgress;
            state.receiptTemplates.errors = [];
        },
        SET_RECEIPT_TEMPLATES_ERROR(state, errors) {
            state.receiptTemplates.status = OperationStatus.Failed;
            state.receiptTemplates.errors = errors;
        },
        SET_RECEIPT_TEMPLATES_SUCCESS(state, data) {
            state.receiptTemplates.status = OperationStatus.Succeeded;
            state.receiptTemplates.data = data;
        },
        SET_TEMPLATE_METADATA_LOADING(state) {
            state.templateMetadata.status = OperationStatus.InProgress
            state.templateMetadata.errors = [];
        },
        SET_TEMPLATE_METADATA_SUCCESS(state, data) {
            const { platformTypes, eventTypes, orderTypes } = data;
            state.templateMetadata.data.platformTypes = platformTypes;
            state.templateMetadata.data.orderStatuses = eventTypes;
            state.templateMetadata.data.orderTypes = orderTypes;
            state.templateMetadata.status = OperationStatus.Succeeded;
        },
        SET_TEMPLATE_METADATA_ERROR(state, errors) {
            state.templateMetadata.status = OperationStatus.Failed;
            state.templateMetadata.errors = errors;
        },
    },
    actions: {
        async fetchReceiptTemplates({dispatch, state, commit, rootState}, waypointId) {
            try {
                commit('SET_RECEIPT_TEMPLATES_LOADING');
                if (!waypointId) {
                    commit('SET_RECEIPT_TEMPLATES_SUCCESS', []);
                    return;
                }
                const request = GetReceiptTemplatesRequest(waypointId, rootState.user.email, process.env.VUE_APP_KOBP);
                const {status, data} = await passthrough(request);
                if (status === 200) {
                    commit('SET_RECEIPT_TEMPLATES_SUCCESS', data);
                } else {
                    commit('SET_RECEIPT_TEMPLATES_ERROR', ['Unexpected error occurred during fetch of Receipt templates']);
                }
            } catch (e) {
                commit('SET_RECEIPT_TEMPLATES_ERROR', ['Unexpected error occurred during fetch of Receipt templates', e]);
            }
        },
        async fetchReceiptTemplateMetadata({ commit }) {
            try {
                commit('SET_TEMPLATE_METADATA_LOADING');
                const request = GetReceiptTemplateMetadataRequest();
                const { data } = await passthrough(request);

                commit('SET_TEMPLATE_METADATA_SUCCESS', data);
            } catch (e) {
                commit('SET_TEMPLATE_METADATA_ERROR', ['Unexpected error during fetch of Template metadata']);
                console.error(e);
            }
        },
    },
    getters: {
        receiptsStatus: state => state.receiptTemplates.status,
        receiptTemplates: state => state.receiptTemplates.data,
        templateMetadataStatus: state => state.templateMetadata.status,
        platformTypes: state => state.templateMetadata.data.platformTypes,
        orderStatuses: state => state.templateMetadata.data.orderStatuses,
        orderTypes: state => state.templateMetadata.data.orderTypes,
        orderStatusLabelById: state => id => {
            if (state.templateMetadata.status !== OperationStatus.Succeeded || typeof id === "undefined") {
                return id.toString();
            }

            return getLabelById(state.templateMetadata.data.orderStatuses, id);
        },
        platformLabelById: state => id => {
            if (id == 0) return "All"
            if (state.templateMetadata.status !== OperationStatus.Succeeded || typeof id === "undefined") {
                return id.toString();
            }

            return getLabelById(state.templateMetadata.data.platformTypes, id);
        },
        orderTypeLabelById: state => id => {
            if (id == 0) return "All"
            if (state.templateMetadata.status !== OperationStatus.Succeeded || typeof id === "undefined") {
                return id.toString();
            }

            return getLabelById(state.templateMetadata.data.orderTypes, id);
        },
    },
}

const GetReceiptTemplatesRequest = (waypointID, email, kobp) => ({
    url: 'CursusHub_GetReceiptTemplatesByWaypoint',
    method: 'GET',
    qs: {
        waypointID,
        email,
        kobp,
    }
});

const GetReceiptTemplateMetadataRequest = () => ({
    url: 'CursusHub_GetMessageTemplateMetadata',
    method: 'GET',
});
