var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "action-button d-flex justify-content-center align-items-center",
      on: { click: _vm.action },
    },
    [
      _c(
        "span",
        {
          staticClass: "action-button__text",
          class: [`action-button__text--${_vm.type}`],
        },
        [_vm._t("default")],
        2
      ),
      _vm.type && !_vm.noIcon
        ? _c("span", {
            staticClass: "action-button__img height-xs--inherit block",
            class: [
              `action-button__img--${_vm.type}`,
              { "block block-xs--xxs-left": _vm.hasText },
            ],
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }