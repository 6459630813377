<template>
    <div class="editing-modal">
        <div class="blur-filter">
            <transition 
                name="shift-fade" 
                appear>
                <modal 
                    :class="[`modal--${size}`]" 
                    class="modal">
                    <div class="editing-modal__inner row no-gutters">
                        <div class="col-12">
                            <slot>
                                <h1 class="block block-xs--xl-top">Yield content</h1>
                            </slot>
                        </div>
                    </div>
                </modal>
            </transition>
        </div>
    </div>
</template>

<script>
import modal from './modal.vue'

export default {
    name: 'EditingModal',

    components: {
        modal,
    },

    props: {
        size: {
            type: String,
            default: () => 'md',
        },
    },
}
</script>

<style lang="scss">
@import '~scss/variables';
@import '~scss/mixins';

.blur-filter {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: zi(modal) - 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: $modal_background;
}

.modal {
    overflow: auto;
    transition: $transition_short;

    &, &--md {
        left: 25vw;
        top: 10vh;
        width: 50vw;
        max-height: 90vh;
    }

    &--lg {
        left: 5vw;
        top: 10vh;
        width: 90vw;
        max-height: 90vh;
    }

    &--sm {
        left: 35vw;
        top: 15vw;
        width: 30vw;
        height: inherit;
    }
    @include below(lg) {
        width: 80vh;
    }
    @include below(md) {
        width: 90vw;
    }
}

.editing-modal {
    &__title {
        font-size: fz(venti);
    }
}

.shift-fade {
    &-enter-active, &-leave-active {
        transition: $transition_short;
    }
    &-enter, &-leave-to {
        opacity: 0;
        transform: translateY(10%);
    }
}
</style>
