// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("img/alert_white.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("img/check_green.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".toast {\n  font-family: \"Roboto\", sans-serif;\n  font-style: normal;\n  font-weight: 700;\n  box-shadow: 0 10px 10px rgba(210, 210, 210, 0.5), 0 -1px 10px rgba(210, 210, 210, 0.5);\n  position: fixed;\n  top: 50px;\n  right: 50px;\n  z-index: 6000;\n  padding: 20px 24px;\n  border-radius: 4px;\n  font-size: 14px;\n  cursor: pointer;\n  line-height: 25px;\n  background-color: #fff;\n}\n.toast:before {\n  content: \"\";\n  background-position: center;\n  background-repeat: no-repeat;\n  display: inline-block;\n  vertical-align: middle;\n  margin-right: 20px;\n}\n.toast--normal:before {\n  margin-right: 0;\n}\n.toast--error {\n  background-color: #f66;\n  color: #fff;\n}\n.toast--error:before {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: 23px 22px;\n  width: 23px;\n  height: 22px;\n}\n.toast--success {\n  background-color: #fffac6;\n  color: #729809;\n}\n.toast--success:before {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  background-size: 23px 20px;\n  width: 23px;\n  height: 20px;\n}\n.toast-enter-active, .toast-leave-active {\n  transition: all 200ms;\n}\n.toast-enter, .toast-leave-to {\n  transform: translateX(50px);\n  opacity: 0;\n}", ""]);
// Exports
module.exports = exports;
