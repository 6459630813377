import Vue from 'vue'
import Router from 'vue-router'
import store from 'store/store.js'
import { IsPermissionedAsGrabber } from 'helpers/access'

Vue.use(Router)

const loadPage = template => {
    // TODO switch to import when that's a bit more stable
    return () => System.import(`./pages/${template}`)
}

const home = loadPage('home')
const login = loadPage('login')
const setPassword = loadPage('set_password')
const resetPassword = loadPage('reset_password')
const resetPasswordConfirm = loadPage('reset_password_confirm')
const management = loadPage('management')
const dashboard = loadPage('dashboard')
const reporting = loadPage('reporting')
const base = loadPage('base')
const storeAdmin = loadPage('store_admin')
const storeAdminSidebar = loadPage('store_admin_sidebar')
const storeProductsOverview = loadPage('store_products')
const storeProductEdit = loadPage('store_product_edit')
const storeProduct = loadPage('store_product')
const storeProductDatabase = loadPage('store_product_add_database')
const storeModIndex = loadPage('store_mod_index')
const storeModDetail = loadPage('store_mod')
const storeModAdd = loadPage('store_mod_add')
const storeModDatabase = loadPage('store_mod_add_database')
const storeModEdit = loadPage('store_mod_edit')
const storeModSetDetail = loadPage('store_mod_set_detail')
const storeModSetEdit = loadPage('store_mod_set_edit')
const storeModGroupDetail = loadPage('store_mod_group')
const storeModGroupEdit = loadPage('store_mod_group_edit')
const storeSettings = loadPage('store_settings')
const storeSettingsEdit = loadPage('store_settings_edit')
const storeSettingsUpsellOverview = loadPage('store_settings_upsell_overview')
const storeSettingsUpsellEdit = loadPage('store_settings_upsell_edit')
const storeSettingsUpsellDetail = loadPage('store_settings_upsell')
const storeSettingsPosDetail = loadPage('store_settings_pos')
const storeSettingsPosEdit = loadPage('store_settings_pos_edit')
const storeSettingsTableNumbers = loadPage('store_settings_table_numbers')
const storeSettingsPlatforms = loadPage('store_settings_platforms.vue')
const storeSettingsSections = loadPage('store_settings_sections')
const storeSettingsSectionsEdit = loadPage('store_settings_sections_edit')
const storeSettingsSectionCreate = loadPage('store_settings_sections_create')
const storeSettingsFunction = loadPage('store_settings_menu_functions.vue')
const storeSettingsTabletop = loadPage('store_settings_tabletop')

const storeImages = loadPage('imagemanager/store_images')
const imageDetails = loadPage('imagemanager/image_details')

const storeProductBulkEdit = loadPage('store_product_bulk_edit')

const operations = loadPage('operations/index.vue')
const operationsSidebar = loadPage('operations/sidebar')
const partners = loadPage('operations/partners')
const sublocations = loadPage('operations/sublocations')
const partnerEdit = loadPage('operations/partner_edit')
const partnerCreate = loadPage('operations/partner_create')
const sublocationEdit = loadPage('operations/sublocation_edit')
const sublocationCreate = loadPage('operations/sublocation_create')
const operationsAirportDeliveryPartner = loadPage('operations/airport_delivery_partner')
const users = loadPage('operations/users/index')
const usersEdit = loadPage('operations/users/edit')
const usersCreate = loadPage('operations/users/create')

const upsellMenuItem = loadPage('upsell/upsell_menu_item')
const upsellMenuItemEdit = loadPage('upsell/upsell_menu_item_edit')
const upsellMenuItemCreate = loadPage('upsell/upsell_menu_item_create')
const upsellGroup = loadPage('upsell/upsell_group')
const upsellGroupEdit = loadPage('upsell/upsell_group_edit')
const upsellGroupCreate = loadPage('upsell/upsell_group_create')
const addonMenuItem = loadPage('upsell/addon_menu_item')
const addonMenuItemCreate = loadPage('upsell/addon_menu_item_create')
const addonMenuItemEdit = loadPage('upsell/addon_menu_item_edit')

const deliveryPartnerAirportManagement = loadPage('delivery_partner_airport_management')
const storeNotes = loadPage('store_notes')
const posNotes = loadPage('pos_notes')

const bulkEdit = loadPage('product_bulk_edit')
const createStoreIndex = loadPage('create_store/index')
const createStoreRequest = loadPage('create_store_request')

const createStoreStoreInformation = loadPage('create_store/wizard/create/index');
const createStoreDesign = loadPage('create_store/wizard/design/index');

const messaging = loadPage('messaging/index');
const messagingEdit = loadPage('messaging/edit');

const receipts = loadPage('receipts/index');
const receiptsEdit = loadPage('receipts/edit');
const receiptsBulkEdit = loadPage('receipts/bulk_edit');
const configs = loadPage('operations/configs');
const styles = loadPage('operations/styles');
const resources = loadPage('operations/resources');
const svgassets = loadPage('operations/svgassets');

const languages = loadPage('languages');
const languagesSidebar = loadPage('languages/sidebar');
const editTranslations = loadPage('languages/edit_translations');

const languageSettings = loadPage('languages/edit_languages')

const buildSettingsRoutes = () => {
    let routes = [
        { path: 'settings', component: storeSettings, name: 'store-settings' },
        { path: 'settings/edit', component: storeSettingsEdit, name: 'store-settings-edit' },
        { path: 'settings/upsell', component: storeSettingsUpsellOverview, name: 'store-upsell-settings' },
        { path: 'settings/upsell/:product', component: storeSettingsUpsellDetail, name: 'store-settings-upsell-item' },
        { path: 'settings/upsell/:product/edit', component: storeSettingsUpsellEdit, name: 'store-upsell-edit-settings' },
        { path: 'settings/pos', component: storeSettingsPosDetail, name: 'store-pos-settings' },
        { path: 'settings/pos/edit', component: storeSettingsPosEdit, name: 'store-pos-settings-edit' },
        { path: 'settings/tablenumbers', component: storeSettingsTableNumbers, name: 'store-settings-table-numbers' },
        { path: 'settings/platforms', component: storeSettingsPlatforms, name: 'store-settings-platforms' },
        { path: 'settings/sections', component: storeSettingsSections, name: 'store-settings-sections' },
        { path: 'settings/sections/create', component: storeSettingsSectionCreate, name: 'store-settings-section-create' },
        { path: 'settings/sections/:SectionID', component: storeSettingsSectionsEdit, name: 'store-settings-sections-edit' },
        { path: 'settings/menu-functions', component: storeSettingsFunction, name: 'store-settings-menu-functions' },
        { path: 'settings/tabletop', component: storeSettingsTabletop, name: 'store-settings-tabletop' },
        { path: 'settings/languages', component: languageSettings, name: 'store-settings-languages'}]

    return [
        ...routes.map(el => {
            return {
                path: `:env/${el.path}`,
                component: el.component,
                name: el.name,
            }
        })
    ]
}

const routes = [
    { path: '/', component: home, meta: { title: 'Welcome' }, redirect: '/store/admin' },
    { path: '/setPassword', name: 'set-password', component: setPassword, meta: { title: 'Set Password' } },
    { path: '/resetPassword', name: 'reset-password', component: resetPassword, meta: { title: 'Reset Password' } },
    { path: '/resetPasswordConfirm', name: 'reset-password-confirm', component: resetPasswordConfirm, meta: { title: 'Success' } },
    { path: '/login', name: 'login', component: login },
    { path: '/delivery', name: 'delivery', component: deliveryPartnerAirportManagement },
    {
        path: '/create-store', component: management, children: [
            {
                path: '/', meta: { title: 'Create Store' }, component: createStoreIndex, children: [
                    { path: 'store-information', name: 'store-information', component: createStoreStoreInformation },
                    { path: 'design', name: 'design', component: createStoreDesign },
                ]
            }
        ]
    },
    {
        path: '/operations', component: management, children: [
            {
                path: '/', name: 'operations', components: {
                    default: operations,
                    sidebar: operationsSidebar,
                }, meta: { title: 'Operations' }, children: [
                    { path: 'partners', name: 'partners', component: partners, meta: { title: 'Partners' } },
                    { path: 'partners/:partner/edit', name: 'partnerEdit', component: partnerEdit, meta: { title: 'Edit Partner' } },
                    { path: 'partners/new', name: 'partnerCreate', component: partnerCreate, meta: { title: 'Create Partner' } },
                    { path: 'partners/:partner/sublocations', name: 'sublocations', component: sublocations, meta: { title: 'Sublocations' } },
                    { path: 'partners/:partner/sublocations/:sublocation/edit', name: 'sublocationEdit', component: sublocationEdit, meta: { title: 'Edit Sublocation' } },
                    { path: 'partners/:partner/sublocations/new', name: 'sublocationCreate', component: sublocationCreate, meta: { title: 'Create Sublocation' } },
                    { path: 'users', name: 'users', component: users, meta: { title: 'Users' } },
                    { path: 'users/:userID/edit', name: 'user-edit', component: usersEdit, meta: { title: 'Edit User' } },
                    { path: 'users/create', name: 'user-create', component: usersCreate, meta: { title: 'Create Users' } },
                    { path: ':env/deliveryPartner', name: 'airport-delivery-partner', component: operationsAirportDeliveryPartner, meta: { title: 'Delivery Management' } },
                    { path: "messaging", name: 'messaging', component: messaging, meta: {title: "Messaging" }},
                    { path: "messaging/edit/:waypointID/:templateID", name: 'messaging-edit', component: messagingEdit, meta: {title: "Edit Message Template" }},
                    { path: "receipts", name: 'receipts', component: receipts, meta: {title: 'Receipts'}},
                    { path: "receipts/edit/:waypointID/:templateID", name: 'receipts-edit', component: receiptsEdit, meta: {title: "Edit Receipt Template" }},
                    { path: "receipts/bulk-edit/:waypointID", name: 'receipts-bulk-edit', component: receiptsBulkEdit, meta: {title: "Bulk Edit Receipt Templates" }},
                    { path: 'configs', name: 'configs', component: configs, meta: {title: "Configs"} },
                    { path: 'styles', name: 'styles', component: styles, meta: {title: "Styles"} },
                    { path: 'resources', name: 'resources', component: resources, meta: {title: "Resources"} },
                    { path: 'svgassets', name: 'svgassets', component: svgassets, meta: {title: "SVG Assets"}}
                ]
            }
        ]
    },
    {
        path: '/store', meta: { title: 'base store' }, component: management, children: [
            { path: 'dashboard', component: dashboard, name: 'dashboard', meta: { title: 'Dashboard' } },
            { path: 'reporting', component: reporting, name: 'reporting', meta: { title: 'Reporting' } },
            { path: 'admin', component: base, name: 'store', meta: { title: 'Store Admin', message: 'Please select a store' } },
            {
                path: 'admin/:store', name: 'store-admin', components: {
                    default:storeAdmin,
                    sidebar: storeAdminSidebar
                }, children: [
                    {path: 'bulk_edit', meta: {title: 'Bulk Edit'}, component: storeProductBulkEdit, name: 'store-product-bulk-edit'},
                    { path: 'products', meta: { title: 'Menu' }, name: 'store-products', component: storeProductsOverview },
                    { path: 'products/edit', meta: { title: 'Bulk Product Edit' }, name: 'product-bulk-edit', component: bulkEdit },
                    { path: 'products/add/new', meta: { title: 'New Product' }, component: storeProductEdit, name: 'store-product-add-new' },
                    { path: 'products/add/database', meta: { title: 'New Database Product' }, component: storeProductDatabase, name: 'store-product-add-database' },
                    { path: 'products/:product', component: storeProduct, name: 'store-product-detail' },
                    { path: 'products/:product/edit', meta: { title: 'Edit' }, component: storeProductEdit, name: 'store-product-edit' },

                    { path: 'mod', component: storeModIndex, name: 'store-mod-index' },

                    { path: 'mod/mod-sets/:set', component: storeModSetDetail, name: 'store-mod-set-detail' },
                    { path: 'mod/mod-sets/:set/edit', component: storeModSetEdit, name: 'store-mod-set-edit' },
                    { path: 'mod/mod-sets/add/new', component: storeModSetEdit, name: 'store-mod-set-add' },

                    { path: 'mod/mod-groups/:group', component: storeModGroupDetail, name: 'store-mod-group-detail' },
                    { path: 'mod/mod-groups/:group/edit', component: storeModGroupEdit, name: 'store-mod-group-edit' },
                    { path: 'mod/mod-groups/add/new', component: storeModGroupEdit, name: 'store-mod-group-add' },

                    { path: 'mod/mods/add', component: storeModAdd, name: 'store-mod-add' },
                    { path: 'mod/mods/:mod', component: storeModDetail, name: 'store-mod-detail' },
                    { path: 'mod/mods/:mod/edit', component: storeModEdit, name: 'store-mod-edit' },
                    { path: 'mod/mods/add/new', component: storeModEdit, name: 'store-mod-add-new' },
                    { path: 'mod/mods/add/database', component: storeModDatabase, name: 'store-mod-add-database' },

                    { path: 'images', component: storeImages, name: 'store-images' },
                    { path: 'images/:inventoryItemID/type/:imageTypeKey', component: imageDetails, name: 'image-details' },

                    { path: 'upsell/item', component: upsellMenuItem, name: 'upsell-menu-item' },
                    { path: 'upsell/item/:TargetID/edit', component: upsellMenuItemEdit, name: 'upsell-menu-item-edit' },
                    { path: 'upsell/item/create', component: upsellMenuItemCreate, name: 'upsell-menu-item-create' },
                    { path: 'upsell/group', component: upsellGroup, name: 'upsell-group' },
                    { path: 'upsell/group/new', component: upsellGroupCreate, name: 'upsell-group-create' },
                    { path: 'upsell/group/:GroupID/edit', component: upsellGroupEdit, name: 'upsell-group-edit' },
                    { path: 'addon/item', component: addonMenuItem, name: 'addon-menu-item' },
                    { path: 'addon/item/create', component: addonMenuItemCreate, name: 'addon-menu-item-create' },
                    { path: 'addon/item/:TargetID/edit', component: addonMenuItemEdit, name: 'addon-menu-item-edit' },

                    { path: 'notes/store', component: storeNotes, name: 'store-notes' },
                    { path: 'notes/pos', component: posNotes, name: 'pos-notes' },
                    {
                        path: 'languages', meta: { title: 'Languages' }, component: languages, name: 'store-languages', children: [
                            { path: 'languages/:language', component: editTranslations, name: 'edit-translations', meta: { title: 'Edit translations' } },
                        ]
                    },
                    ...buildSettingsRoutes(),

                ]
            },
        ]
    },
]

let router = new Router({
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        let ret = { x: 0, y: 0 }
        if (to.hash) {
            ret = { selector: to.hash }
        } else if (savedPosition) {
            ret = savedPosition
        }
        return ret
    },
    routes,
})

router.beforeEach(function (to, from, next) {
    // console.log(to)
    // console.log(from)
    // console.log(next)
    const accessToken = localStorage.getItem('accessToken')
    document.title = 'Grab Hub'

    if (store.state.user.deliveryPartner && to.name != 'delivery') {
        return next({ name: 'delivery' })
    }

    if ((to.name !== 'login' && to.name !== 'set-password' && to.name !== 'reset-password' && to.name !== 'reset-password-confirm') && (!accessToken || store.state.user == false)) {
        localStorage.setItem('pendingRoute', JSON.stringify(to))
        return next({ name: 'login' })
    }

    if (to.name == 'store') {
        let stored = localStorage.getItem('pendingRoute')
        if (stored != null && stored != undefined) {
            localStorage.removeItem('pendingRoute')
            return next(JSON.parse(stored))
        }
    }

    if (to.meta && to.meta.title) {
        document.title = to.meta.title
    }

    if (from.params.store != undefined && to.params.store != undefined && from.params.store != to.params.store) {
        store.dispatch('SettingsStore/handleStoreParamChange')
    }

    if (store.state.user == undefined || store.state.user == false) return next()
    if (store.state.user.email == undefined || IsPermissionedAsGrabber(store.state.user) || store.state.user.storeAccess == undefined) return next();

    let access = store.state.user.storeAccess
    let allowedStoreIDs = access.map(el => el.storeWaypointID)

    if (to.params.store != undefined) {
        if (allowedStoreIDs.includes(parseInt(to.params.store))) {
            return next()
        }
        return next({ name: 'store' })
    }
    return next()

})



export default router
