import Vue from 'vue'
import Vuex, {createLogger} from 'vuex'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)
let ls = new SecureLS({ isCompression: false });
import SecureLS from "secure-ls";

import state from './state'
import getters from './getters'
import actions from './actions'
import mutations from './mutations'

import ModEditStore from './mod_edit_store'
import AirportStore from './airport_store'
import ImageUploadStore from './image_upload_store'
import IdleStore from './idle_store'
import PartnerStore from './partner_store'
import UpsellStore from './upsell_store'
import UserStore from "./users_store"
import DeliveryStore from "./delivery_store"
import NoteStore from "./note_store"
import SettingsStore from './settings_store'
import POSReloadStore from './pos_reload_store'
import MessagingStore from './messaging_store'
import ReceiptsStore from './receipts_store'
import FutureSyncStore from './future_sync_store'
import ExplorerStore from './explorer_store';
import ConfigStore from './config_store';

export default new Vuex.Store({
    state,
    getters,
    actions,
    mutations,
    strict: true,
    plugins: [
        createPersistedState({
            storage: {
                getItem: key => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: key => ls.remove(key)
            },
            paths: ['user', 'IdleStore', 'PartnerStore', 'modificationEmailSent', 'POSReloadStore', 'menuIntegrity'],
        }),
    ],
    modules: {
        ModEditStore,
        AirportStore,
        ImageUploadStore,
        IdleStore,
        PartnerStore,
        UpsellStore,
        UserStore,
        DeliveryStore,
        NoteStore,
        SettingsStore,
        POSReloadStore,
        MessagingStore,
        ReceiptsStore,
        FutureSyncStore,
        ExplorerStore,
        ConfigStore
    }
})
