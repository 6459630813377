import moment from 'moment'

export const getLocalTime = (time, offset) => {
    return moment(time + ` ${offset}:00`, 'h:mm A Z').format('h:mm A')
}

export const stripDateString = date => {
    return date.replace(/\/Date\(/, '').replace(/\)\//, '')
}

export const dateStringToDate = date => {
    // const strippedDate = stripDateString(date)
    return moment.utc(date).format('M/D/YY')
}

export const dateStringToTime = date => {
    // const strippedDate = stripDateString(date)
    return moment.utc(date).format('h:mm A')
}

export const timeStringToDate = time => {
    return moment.utc(time, 'h:mm: A').format('x')
}

export const getDateRange = (start, end) => {
    return [start, end]
        .join('–')
}

export const parseCost = (costDisplay) => {
    let ret = costDisplay.split(' - ')
    if (ret.length > 1) {
        ret = `${ret[0]}—${ret[1]}`
    } else {
        ret = ret[0]
    }
    return ret
}

export const parseTaxRate = rate => {
    const percent = Number(rate * 100)
    const intPercent = parseInt(percent, 10)

    if (isNaN(percent)) {
        return 0
    }

    if (intPercent === percent) {
        return intPercent
    }

    return percent.toFixed(5)
}

export const findClone = (arr, cb) => {
    if (!arr.length) {
        return undefined
    }

    const newSet = arr.filter(cb)

    if (!newSet.length) {
        return undefined
    }

    return (typeof newSet[0] === 'object') ? Object.assign({}, newSet[0]) : newSet[0]
}

export const spreadItems = (itemsArr, blankMsg = 'None') => {
    return itemsArr.length ? itemsArr.join(', ') : blankMsg
}

export const toggleItem = (arr, item, value = item => item) => {
    const copy = arr.concat([])
    const index = copy.findIndex(el => value(el) === item)

    if (index !== -1) {
        copy.splice(index, 1)
    } else {
        copy.push(item)
    }

    return copy
}

export const mapActionButtons = ({ bus, vm }) => {
    const expandCollapse = [
        { type: 'expand', run: () => bus.$emit('expandAllItems') },
        { type: 'collapse', run: () => bus.$emit('collapseAllItems') },
    ]

    const duplicate = {
        type: 'duplicate',
        display: 'Duplicate',
        run: () => console.log('duplicating'),
    }

    const remove = {
        type: 'delete',
        run: () => {
            vm.pendingDelete = vm.activeItems
        },
    }

    const edit = {
        type: 'edit',
        display: 'Edit',
        run: () => {
            bus.$emit('expandAllItems')
            vm.editMode = true
        },
    }

    const cancel = {
        type: 'cancel',
        display: 'Cancel',
        run: () => {
            bus.$emit('collapseAllItems')
            vm.reset()
        },
    }

    const save = {
        type: 'proceed',
        display: 'Save',
        run: () => {
            bus.$emit('collapseAllItems')

            if (vm.pendingDelete.length) {
                vm.remove(vm.pendingDelete)
            }

            vm.save()
            vm.reset()
        },
    }

    if (vm.editMode) {
        if (vm.activeItems.length) {
            return [
                ...expandCollapse,
                // duplicate,
                remove,
                cancel,
                save,
            ]
        }

        if (vm.hasPendingChanges || vm.changedItems && vm.changedItems.length || vm.categoriesSorted) {
            return [...expandCollapse, cancel, save]
        }

        return [...expandCollapse, cancel]
    }

    return [...expandCollapse, edit]
}

export const getRandomNum = () => Math.floor(Math.random() * 100000)

export const chunk = (arr, size) => {
    let ret = []
    let i = 0

    while (i < arr.length) {
        ret.push(arr.slice(i, i + size))
        i += size
    }

    return ret
}

export const extractWeekHours = dayStr => {
    const displayDays = {
        mo: 'Monday',
        tu: 'Tuesday',
        we: 'Wednesday',
        th: 'Thursday',
        fr: 'Friday',
        sa: 'Saturday',
        su: 'Sunday',
    }

    let dayArr = dayStr.split(';')
    dayArr.pop()

    const chunkedDays = chunk(dayArr, 3)
    const keyedChunkedDays = chunkedDays.map(day => {

        return {
            day: day[0],
            start: day[1],
            end: day[2],
            type: ((day[1] === '12:00 AM') && (day[2] === '11:59 PM')) ? 'all-day' : 'custom',
        }
    })

    const ret = keyedChunkedDays.map(el => {
        const isNext = el.end.endsWith('+')
        const end = isNext ? el.end.slice(0, -1) : el.end

        const fullDisplay = displayDays[el.day]
        const display = fullDisplay.slice(0, 3)

        return Object.assign(el, {
            nextDay: isNext,
            fullDisplay,
            display,
            end,
        })
    })

    return ret
}

export const removeDups = (arr, prop) => {
    if (!Array.isArray(arr)) {
        return []
    }

    const seen = new Set()

    return arr.filter(el => {
        if (!seen.has(el[prop])) {
            seen.add(el[prop])
            return true
        } else {
            return false
        }
    })
}

export const upsert = (arr, item) => {
    const index = arr.indexOf(item)

    if (index === -1) {
        return arr.concat([item])
    }

    return arr
}

export const immutableDelete = (arr, index) => {
    return arr.slice(0, index).concat(arr.slice(index + 1))
}

export const localToGlobal = (el) => {
    var target = el,
        target_width = target.offsetWidth,
        target_height = target.offsetHeight,
        target_left = target.offsetLeft,
        target_top = target.offsetTop,
        gleft = 0,
        gtop = 0,
        rect = {};

    var moonwalk = function (_parent) {
        if (_parent) {
            gleft += _parent.offsetLeft;
            gtop += _parent.offsetTop;
            moonwalk(_parent.offsetParent);
        } else {
            return rect = {
                top: target.offsetTop + gtop,
                left: target.offsetLeft + gleft,
                bottom: (target.offsetTop + gtop) + target_height,
                right: (target.offsetLeft + gleft) + target_width
            };
        }
    };
    moonwalk(target.offsetParent);
    return rect;
}

export const priceItem = (item) => {
    const sizes = item.pos_productSizes;

    if (!sizes.length) {
        return "N/A";
    }
    var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
    });
    return `${this.store.currencySymbol}${Number(sizes[0].cost).toFixed(2)}`;

}

export const convertObjectToSelectOption = obj => Object.entries(obj).map(([id, name]) => ({
    text: name,
    value: id,
}));

export const getArrayFromQueryParam = (value) => {
    if (!value) {
        return [];
    }

    if (Array.isArray(value)) {
        return value;
    }

    return [value];
};

export const getLabelById = (obj, id) => _.chain(obj)
    .entries()
    .filter(([key,]) => key === id.toString())
    .map(([_, value]) => value)
    .first()
    .value() || id.toString();
