<template>
    <div class="container-fluid main">
        <div class="row no-gutters">
            <div class="col">
                <router-view />
            </div>

            <toast />

            <loading v-if="loading" />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Toast from 'components/toast'
import Loading from 'components/loading'
import IdleWatcher from '../mixins/idle_watcher'

export default {
    name: 'App',
    components: {
        Toast,
        Loading,
    },
    mixins: [
        IdleWatcher
    ],
    computed: mapState({
        loading: state => state.loading,
    }),

}
</script>

<style lang="scss">
@import '~scss/variables';
@import '~scss/mixins';

.main {
    padding: 0;
}

</style>
