var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "toast" } }, [
    _vm.toastMessage.message && _vm.toastMessage.type
      ? _c(
          "div",
          {
            staticClass: "toast",
            class: [`toast--${_vm.toastMessage.type}`],
            on: { click: _vm.clear },
          },
          [_vm._v(" " + _vm._s(_vm.toastMessage.message) + " ")]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }