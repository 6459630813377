export default {
    namespaced: true,
    state: {
        activeTab: "StoreModSetList"
    },
    mutations: {
        SET_ACTIVE_TAB: (state, tab) => {
            state.activeTab = tab
        },
    },
    actions: {
        setActiveTab: ({commit}, tab) => {
            commit('SET_ACTIVE_TAB', tab)
        }
    },
    getters: {
        activeTab: state => {
            return state.activeTab
        },
    }
}