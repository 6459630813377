
exports.commit = product => sub => {
    return sub

    // const ret = Object.assign(sub, {
    //     pos_productID: product.pos_productID,
    // })

    // return ret
}

exports.revert = sub => {
    const ret = Object.assign(sub, {
        sku: sub.sku || sub.id || '',
        // sku: ((sub.pos_sizeID && sub.pos_sizeID.length) || (sub.inventoryItemSubName === 'MAIN')) ? sub.sku : sub.id,
        cost: sub.cost === undefined ? sub.price : sub.cost,
        inventoryItemSubName: sub.name || sub.inventoryItemSubName,
    })
    delete ret.price
    delete ret.name

    return ret
}
