import clone from 'clone'
import { getRandomNum } from 'helpers'
import { IsPermissionedAsGrabber } from 'helpers/access.js'

export default {
    timeOffset: state => state.activeStore.GMTOffset || '-5',

    store: state => {
        return clone(state.activeStore)
    },

    inited: ({ activeStore }) => Object.keys(activeStore).length > 0,

    user: ({ user }) => user,

    pos: (state, getters) => {
        const store = getters.store
        return store.storePOS
    },

    integrated: (state, getters) => {
        return getters.pos !== 'grab'
    },

    posProducts: state => {
        const { inventoryPos } = state

        if (!inventoryPos) {
            return false
        }

        return inventoryPos.pos_products
    },

    posGroups: state => {
        const { inventoryPos } = state

        if (!inventoryPos) {
            return false
        }

        return inventoryPos.pos_modifierGroups
    },

    posItems: state => clone(state.posItems),

    posMods: state => clone(state.posMods),

    productCategories: state => {
        const categories = state.productCategories

        if (!categories.length) {
            return []
        }

        return clone(categories)
    },

    productAttributes: state => {
        return state.activeStore.inventoryItemAttributeTypes
    },

    products: state => {
        if (state.activeStore.inventoryItemMains) {
            return clone(state.activeStore.inventoryItemMains.map(el => {
                let copy = Object.assign({}, el)
                copy.id = copy.inventoryItemID

                return copy
            }))
        }

        return []
    },

    productsMap: state => {
        var map = {}
        let mains = clone(state.activeStore.inventoryItemMains)
        if (mains === undefined) return undefined

        mains.forEach(el => {
            map[el.inventoryItemID] = el
        })

        return map
    },

    upsellItems: state => {
        const upsellItems = state.activeStore.upsaleV2

        if (!upsellItems) {
            return []
        }

        return clone(upsellItems)
    },

    modSets: state => clone(state.modSets),

    modGroups: (state, getters) => realData => clone(realData ? state.modGroups.map(modGroup => ({
        optionGroupAvailable: modGroup.enabled,
        optionGroupName: modGroup.title,
        optionID: modGroup.id,
        optionGroupDisplayOrder: modGroup.sortOrder,
        inventoryOptions: getters.mods(true),
    })) : state.modGroups),

    mods: state => realData => {
        const copy = clone(state.mods)

        return realData ? copy.map(mod => ({
            optionDescription: mod.title,
            optionAvailable: mod.enabled,
            optionOrder: mod.sortOrder,
            optionID: mod.sku,
        })) : copy.map(mod => {
            mod.sku = getRandomNum()
            mod.path = `${getRandomNum()}/${getRandomNum()}/${getRandomNum()}`
            mod.defaultTax = true
            mod.pendingDelete = false
            mod.taxRate = 0.1
            mod.taxes = [
                {Amount: 0.1, Description: 'State'},
                {Amount: 0.1, Description: 'Local'},
                {Amount: 0, Description: 'Other'},
            ]
            mod.platforms = ['Mobile', 'Kiosk', 'Table Top']
            mod.options = []
            mod.startTime = '2:00 PM'
            mod.endTime = '7:00 PM'
            mod.attributes = []
            mod.tags = ['coke', 'drink']

            return mod
        })
    },
    storeReloadProgress: state => {
        return {
            complete: state.reloadRequestComplete,
            reloadItemsLoaded: state.reloadItemsLoaded,
            reloadItemsToLoad: state.reloadItemsToLoad
        }
    },
    storeReloadMap: state => {
        return state.reloadMap
    },
    dev: state => {
        return state.dev
    },
    storeImage: state => {
        return state.activeStore.storeImageName
    },
    superuser: state => {
        return IsPermissionedAsGrabber(state.user)
    },
    getMenuIntegrity: state => waypointId => {
        return state.menuIntegrity[waypointId];
    },
    inventoryItemIDsWithDayPartingEnabled: state => {
        return state.inventoryItemIDsWithDayPartingEnabled
    }

    ///ACCESS

}
