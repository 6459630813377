import { authHttp, passthrough } from '../http'
import clone from 'clone'


const initStoreSpecificState = () => {
    return {
        tabletSettingsStage: undefined,
        tabletSettingsProd: undefined,
        tabletopSettingsStage: undefined,
        tabletopSettingsProd: undefined,
        tableNumbersStage: undefined,
        tableNumbersProd: undefined,
        sectionsStage: undefined,
        sectionsProd: undefined,
        platformsStage: undefined,
        platformsProd: undefined,
    }
}
export default {
    namespaced: true,
    state: {
        ...initStoreSpecificState()
    },
    mutations: {
        CLEAR_STORE_SPECIFIC_STATE: (state) => {
            const s = initStoreSpecificState()
            Object.keys(s).forEach(key => {
                state[key] = s[key]
            })
        },
        RECIEVE_WAYPOINTS_WITH_TABLENUMBERS: (state, waypointsWithTableNumbers) => {
            state.waypointsWithTableNumbers = waypointsWithTableNumbers
        },
        RECIEVE_TABLET_SETTINGS: (state, { settings, env }) => {
            if (env == "stage") {
                state.tabletSettingsStage = settings
            } else if (env == "prod") {
                state.tabletSettingsProd = settings
            }
        },
        RECIEVE_TABLETOP_SETTINGS: (state, { settings, env }) => {
            if (env == "stage") {
                state.tabletopSettingsStage = settings
            } else if (env == "prod") {
                state.tabletopSettingsProd = settings
            }
        },
        RECIEVE_TABLE_NUMBERS: (state, { tableNumbers, env }) => {
            if (env == "stage") {
                state.tableNumbersStage = tableNumbers
            } else if (env == "prod") {
                state.tableNumbersProd = tableNumbers
            }
        },
        RECIEVE_SECTIONS: (state, { sections, env }) => {
            if (env == "stage") {
                state.sectionsStage = sections
            } else if (env == "prod") {
                state.sectionsProd = sections
            }
        },
        RECIEVE_PLATFORMS: (state, { platforms, env }) => {
            if (env == "stage") {
                state.platformsStage = platforms
            } else if (env == "prod") {
                state.platformsProd = platforms
            }
        }
    },
    actions: {
        getTabletSettings: ({ commit }, { storeWaypointID, env }) => {
            commit('SET_LOADING', true, { root: true })

            let request = GetTabletSettingsRequest(storeWaypointID, env)
            return passthrough(request).then(res => {
                commit('RECIEVE_TABLET_SETTINGS', { settings: res.data, env })
            }).finally(() => {
                commit('SET_LOADING', false, { root: true })
            })
        },
        saveTabletSettings: ({ commit }, { storeWaypointID, env, settings }) => {
            commit('SET_LOADING', true, { root: true })

            let request = SaveTabletSettingsRequest(storeWaypointID, env, settings)
            return passthrough(request).then(res => {
                commit('RECIEVE_TABLET_SETTINGS', { settings: res.data, env })
            }).finally(() => {
                commit('SET_LOADING', false, { root: true })
            })
        },
        getTableTopSettings: ({ commit }, { storeWaypointID, env }) => {
            commit('SET_LOADING', true, { root: true })

            let request = GetTableTopSettingsRequest(storeWaypointID, env)
            return passthrough(request).then(res => {
                commit('RECIEVE_TABLETOP_SETTINGS', { settings: res.data, env })
            }).catch(() => {

            }).finally(() => {
                commit('SET_LOADING', false, { root: true })
            })
        },
        saveTableTopSettings: ({ commit }, { storeWaypointID, env, settings }) => {
            commit('SET_LOADING', true, { root: true })

            let request = SaveTableTopSettingsRequest(storeWaypointID, env, settings)
            return passthrough(request).then(res => {
                commit('RECIEVE_TABLETOP_SETTINGS', { settings: res.data, env })
            }).finally(() => {
                commit('SET_LOADING', false, { root: true })
            })
        },
        handleStoreParamChange: ({ commit }) => {
            commit('CLEAR_STORE_SPECIFIC_STATE')
        },
        getSections: ({ commit, state }, { waypointID, env }) => {

            if (env == "stage" && state.sectionsStage != undefined) return
            if (env == "prod" && state.sectionsProd != undefined) return

            commit('SET_LOADING', true, { root: true })
            let request = GetSectionsRequest(waypointID, env)
            return passthrough(request).then(res => {
                commit('RECIEVE_SECTIONS', { sections: res.data, env })
                return res.data
            }).finally(() => {
                commit('SET_LOADING', false, { root: true })
            })
        },
        updateSection: ({ commit }, payload) => {
            commit('SET_LOADING', true, { root: true })
            let request = UpdateSectionRequest(payload)
            return passthrough(request).then(res => {
                commit('RECIEVE_SECTIONS', { env: payload.env, sections: res.data })
                return res.data
            }).finally(() => {
                commit('SET_LOADING', false, { root: true })
            })
        },
        createSection: ({ commit }, payload) => {
            commit('SET_LOADING', true, { root: true })
            let request = CreateSectionRequest(payload)
            return passthrough(request).then(res => {
                commit('RECIEVE_SECTIONS', { env: payload.env, sections: res.data })
                return res.data
            }).finally(() => {
                commit('SET_LOADING', false, { root: true })
            })
        },
        deleteSectionByWaypointID: ({ commit }, { storeWaypointID, sectionID, env }) => {
            commit('SET_LOADING', true, { root: true })
            let request = DeleteSectionRequest(storeWaypointID, sectionID, env)
            return passthrough(request).then(res => {
                commit('RECIEVE_SECTIONS', { env, sections: res.data })
                return res.data
            }).finally(() => {
                commit('SET_LOADING', false, { root: true })
            })
        },
        getTableNumbers: ({ commit, state }, { waypointID, env }) => {
            if (env == "stage" && state.tableNumbersStage != undefined) return
            if (env == "prod" && state.tableNumbersProd != undefined) return

            commit('SET_LOADING', true, { root: true })

            return authHttp.get(`/api/waypoint/${waypointID}/orderlocations?env=${env}`).then(res => {
                if (res.data.waypoints === undefined || res.data.waypoints.length === 0) return []
                commit('RECIEVE_TABLE_NUMBERS', { env, tableNumbers: res.data.waypoints[0].locations })
                return res.data.waypoints[0].locations
            }).finally(
                commit('SET_LOADING', false, { root: true })
            )
        },
        saveTableNumberChanges: ({ commit, dispatch }, { waypointID, tableNumbers, env }) => {
            if (!tableNumbers || tableNumbers.length == 0) {
                return Promise.resolve()
            }
            commit('SET_LOADING', true, { root: true })
            let request = SetOrderLocationsOnlineRequest(waypointID, clone(tableNumbers), env)
            return passthrough(request).then(res => {
            }).finally(() => {
                commit('SET_LOADING', false, { root: true })
                return dispatch('getTableNumbersForWaypoint', { waypointID, env })
            })
        },
        setAllTablesOnline: ({ commit, dispatch }, { waypointID, env }) => {
            commit('SET_LOADING', true, { root: true })
            let request = SetAllOnlineRequest(waypointID, env)
            return passthrough(request).then(res => {
            }).finally(() => {
                commit('SET_LOADING', false, { root: true })
                return dispatch('getTableNumbersForWaypoint', { waypointID, env })
            })
        },
        setAllTablesOffline: ({ commit, dispatch }, { waypointID, env }) => {
            commit('SET_LOADING', true, { root: true })
            let request = SetAllOfflineRequest(waypointID, env)
            return passthrough(request).then(res => {
            }).finally(() => {
                commit('SET_LOADING', false, { root: true })
                return dispatch('getTableNumbersForWaypoint', waypointID)
            })
        },
        savePlatforms({ commit, state }, { waypointID, env, platforms }) {
            commit('SET_LOADING', true, { root: true })
            let request = SavePlatformsRequest(waypointID, env, platforms)
            return passthrough(request).then((res) => {
                commit('RECIEVE_PLATFORMS', {env, platforms: res.data })
            }).finally(() => {
                commit('SET_LOADING', false, { root: true })
            })
        },
        getPlatforms({ commit, state }, { waypointID, env }) {
            if (env == "stage" && state.platformsStage != undefined) return
            if (env == "prod" && state.platformsProd != undefined) return

            commit('SET_LOADING', true, { root: true })
            let request = GetPlatformsRequest(waypointID, env)
            return passthrough(request).then((res) => {
                commit('RECIEVE_PLATFORMS', {env, platforms: res.data })
            }).finally(() => {
                commit('SET_LOADING', false, { root: true })
            })
        },
    },
    getters: {
        tabletSettingsStage: state => {
            return state.tabletSettingsStage
        },
        tabletSettingsProd: state => {
            return state.tabletSettingsProd
        },
        tabletopSettingsStage: state => {
            return state.tabletopSettingsStage
        },
        tabletopSettingsProd: state => {
            return state.tabletopSettingsProd
        },
        tableNumbersStage: state => {
            return state.tableNumbersStage
        },
        tableNumbersProd: state => {
            return state.tableNumbersProd
        },
        sectionsStage: state => {
            return state.sectionsStage
        },
        sectionsProd: state => {
            return state.sectionsProd
        },
        platformsStage: state => {
            return state.platformsStage
        },
        platformsProd: state => {
            return state.platformsProd
        }
    }
}

let GetPlatformsRequest = (storeWaypointID, env) => {
    return {
        url: 'CursusHub_GetStorePlatforms',
        method: 'GET',
        qs: {
            storeWaypointID,
            env
        },

    }
}

let SavePlatformsRequest = (storeWaypointID, env, platforms) => {
    return {
        url: 'CursusHub_SaveStorePlatforms',
        method: 'POST',
        qs: {
            storeWaypointID,
            env,
        },
        body: {
            platforms
        }
    }
}

let GetTabletSettingsRequest = (storeWaypointID, env) => {
    return {
        url: 'CursusHub_GetTabletSettings',
        method: 'GET',
        qs: {
            storeWaypointID,
            env
        },
    }
}

let SaveTabletSettingsRequest = (storeWaypointID, env, settings) => {
    return {
        url: 'CursusHub_SetTabletSettings',
        method: 'POST',
        body: settings,
        qs: {
            storeWaypointID,
            env,
        }
    }
}


let GetTableTopSettingsRequest = (storeWaypointID, env) => {
    return {
        url: 'CursusHub_GetTabletopSettings',
        method: 'GET',
        qs: {
            storeWaypointID,
            env
        },
    }
}

let SaveTableTopSettingsRequest = (storeWaypointID, env, settings) => {
    return {
        url: 'CursusHub_SetTabletopSettings',
        method: 'POST',
        body: settings,
        qs: {
            storeWaypointID,
            env,
        }
    }
}

let SetOrderLocationsOnlineRequest = (storeWaypointID, tableNumbers, env) => {
    return {
        url: 'CursusHub_SetOrderLocationsOnline',
        method: 'POST',
        qs: {
            storeWaypointID,
            env,
        },
        body: tableNumbers
    }
}

let SetAllOnlineRequest = (storeWaypointID, env) => {
    return {
        url: 'CursusHub_SetAllOrderLocationsOnline',
        method: 'GET',
        qs: {
            storeWaypointID,
            env,
        },
    }
}

let SetAllOfflineRequest = (storeWaypointID, env) => {
    return {
        url: 'CursusHub_SetAllOrderLocationsOffline',
        method: 'GET',
        qs: {
            storeWaypointID,
            env
        },
    }
}

let GetSectionsRequest = (storeWaypointID, env) => {
    return {
        url: 'CursusHub_GetSectionsWithOrderLocations',
        method: 'GET',
        qs: {
            storeWaypointID,
            env
        },
    }
}

let UpdateSectionRequest = (payload) => {
    return {
        url: 'CursusHub_UpdateSection',
        method: 'POST',
        qs: {
            storeWaypointID: payload.storeWaypointID,
            env: payload.env
        },
        body: payload
    }
}

let CreateSectionRequest = (payload) => {
    return {
        url: 'CursusHub_CreateSection',
        method: 'POST',
        qs: {
            storeWaypointID: payload.storeWaypointID,
            env: payload.env
        },
        body: payload
    }
}

let DeleteSectionRequest = (storeWaypointID, sectionID, env) => {
    return {
        url: 'CursusHub_DeleteSection',
        method: 'GET',
        qs: {
            storeWaypointID: storeWaypointID,
            sectionID: sectionID,
            env,
        },
    }
}