import { http, authHttp } from '../http'
import clone from 'clone'
import Vue from 'vue'

const trimPartner = (partner) => {
    var cloned = clone(partner)

    delete cloned.id
    delete cloned.commercialContactTitle
    delete cloned.financialContactTitle
    delete cloned.city

    return cloned
}

const bloatPartner = (partner) => {
    return {
        ...partner,
        id: partner.partnerShortCode,
        city: '',
        commercialContactTitle: '',
        financialContactTitle: '',
    }
}

const trimSublocation = (sublocation) => {
    var cloned = clone(sublocation)
    delete cloned.waypoints
    delete cloned.posSystem
    return cloned
}

const baseState = {
    partners: [],
    countries: {},
    POS: {},
    airportLocations: {},
    partnerAssignees: [
        'Mark Bergsrud',
        'Jeff Livney',
        'Avi Robinson',
    ],
    canEdit: true,
}

export default {
    namespaced: true,
    state: clone(baseState),
    mutations: {
        CLEAR: (state) => {
            const newState = clone(baseState)

            Object.keys(newState).forEach(key => {
                state[key] = newState[key]
            })
        },
        SET_PARTNERS: (state, partners) => {
            state.partners = partners.map(bloatPartner)
        },
        SET_PARTNER_COUNTRIES: (state, countries) => {
            state.countries = countries
        },
        SET_PARTNER_POS: (state, POS) => {
            state.POS = POS
        },
        SET_AIRPORT_LOCATIONS: (state, airportLocations) => {
            state.airportLocations = airportLocations
        },
        UPDATE_PARTNER: (state, partner) => {
            const index = state.partners.findIndex(el => el.partnerShortCode == partner.partnerShortCode)
            const bloated = bloatPartner(partner)

            Vue.set(state.partners, index, bloated)
        },
        ADD_PARTNER: (state, partner) => {
            state.partners.push(partner)
        }
    },
    actions: {
        clearPartnerStore: {
            root: true,
            handler({ commit }) {
                commit('CLEAR')
            }
        },
        loadAllPartnerInformation: ({ commit, state }) => {

            if (state.partners.length > 0) return 

            commit('SET_LOADING', true, {root: true})

            return Promise.all([
                authHttp.get('/api/partners'),
                authHttp.get('/api/partnerCountries'),
                authHttp.get('/api/partnerPOS'),
                authHttp.get('/api/partnerAirportLocations')
            ]).then(([partnerResponse, countryResponse, POSResponse, airportLocationsResponse]) => {
                commit('SET_PARTNERS', partnerResponse.data.cursusPartners)
                commit('SET_PARTNER_COUNTRIES', countryResponse.data)
                commit('SET_PARTNER_POS', POSResponse.data)
                commit('SET_AIRPORT_LOCATIONS', airportLocationsResponse.data)
                commit('SET_LOADING', false, {root: true})
            })
        },
        updatePartner: ({ commit }, partner) => {
            commit('SET_LOADING', true, {root: true})
            
            const { partnerId } = partner
            
            const payload = trimPartner(partner)

            return authHttp.put(`/api/partner/${partnerId}`, {
                data: payload
            }).then(({ data }) => {
                commit('SET_LOADING', false, {root: true})
                commit('SET_PARTNERS', data.cursusPartners)
            }).catch(err => {
                commit('SET_LOADING', false, {root: true})
                commit('SET_TOAST', {
                    message: err.data,
                    type: 'error',
                }, {root: true})

                throw err
            })
        },
        createPartner: ({ commit }, partner) => {
            commit('SET_LOADING', true, {root: true})
                        
            const payload = trimPartner(partner)

            return authHttp.post('/api/partners', {
                data: payload
            }).then(({ data }) => {
                commit('SET_LOADING', false, {root: true})
                commit('SET_PARTNERS', data.cursusPartners)
            }).catch(err => {
                commit('SET_LOADING', false, {root: true})
                commit('SET_TOAST', {
                    message: err.data,
                    type: 'error',
                }, {root: true})

                throw err
            })
        },
        deletePartner: ({ commit }, partnerId) => {
            commit('SET_LOADING', true, {root: true})
                        
            const payload = {
                partnerShortCode: partnerId
            }

            return authHttp.delete(`/api/partner/${partnerId}`)
                .then(({ data }) => {
                    commit('SET_LOADING', false, {root: true})
                    commit('SET_PARTNERS', data.cursusPartners)
                }).catch(err => {
                    commit('SET_LOADING', false, {root: true})
                    commit('SET_TOAST', {
                        message: err.data,
                        type: 'error',
                    }, {root: true})

                    throw err
                })
        },
        updateSublocation: ({ commit }, sublocation) => {
            commit('SET_LOADING', true, {root: true})
            
            const partnerId = sublocation.partnerShortCode
            const sublocationId = sublocation.subLocationID
            
            const payload = trimSublocation(sublocation)

            return authHttp.put(`/api/partner/${partnerId}/sublocation/${sublocationId}`, {
                data: payload
            }).then(({ data }) => {
                commit('SET_LOADING', false, {root: true})
                // commit('SET_PARTNERS', data.cursusPartners)
            }).catch(err => {
                commit('SET_LOADING', false, {root: true})
                commit('SET_TOAST', {
                    message: err.data,
                    type: 'error',
                }, {root: true})

                throw err
            })
        },
        createSublocation: ({ commit }, sublocation) => {
            commit('SET_LOADING', true, {root: true})
            
            const partnerId = sublocation.partnerShortCode
            
            const payload = trimSublocation(sublocation)

            return authHttp.post(`/api/partner/${partnerId}/sublocations`, {
                data: payload
            }).then(({ data }) => {
                commit('SET_LOADING', false, {root: true})
                // commit('SET_PARTNERS', data.cursusPartners)
            }).catch(err => {
                commit('SET_LOADING', false, {root: true})
                commit('SET_TOAST', {
                    message: err.data,
                    type: 'error',
                }, {root: true})

                throw err
            })
        },
    },
    getters: {
        partnerList: state => {
            return state.partners
        },
        partnerAssignees: state => {
            return state.partnerAssignees
        },
        countries: state => {
            return state.countries
        },
        posSystems: state => {
            return state.POS
        },
        airports: state => {
            return state.airportLocations
        },
        canEdit: state => {
            return state.canEdit
        }
    }
}