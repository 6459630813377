import { authHttp, passthrough } from '../http'
import _ from 'lodash'
import router from '../routes'
import Vue from 'vue'

export default {
    namespaced: true,
    state: {
        storeUpsellOverviews: {},
        storeUpsellGroups: {},
        storeAddonOverviews: {},
        storeExclusionaryUpsellOverviews: {},
        storeExclusionaryAddonOverviews: {},
    },
    mutations: {

        RECIEVE_STORE_UPSELL_OVERVIEW: (state, { waypointID, overview }) => {
            Vue.set(state.storeUpsellOverviews, waypointID, overview)
        },
        RECIEVE_STORE_UPSELL_GROUPS: (state, { waypointID, groups }) => {
            Vue.set(state.storeUpsellGroups, waypointID, groups)
        },
        RECIEVE_STORE_ADDON_OVERVIEW: (state, { waypointID, overview }) => {
            Vue.set(state.storeAddonOverviews, waypointID, overview)
        },
        RECIEVE_STORE_EXCLUSIONARY_ADDON_OVERVIEW: (state, { waypointID, overview }) => {
            var o = overview.map(el => {
                return {
                    ...el,
                    UpsellAddonItems: el.UpsellAddonItems.map(eel => {
                        if (eel.Position > 0) return eel
                        return {
                            ...eel,
                            Position: 1
                        }
                    })
                }
            })
            Vue.set(state.storeExclusionaryAddonOverviews, waypointID, o)
        },
        RECIEVE_STORE_EXCLUSIONARY_UPSELL_OVERVIEW: (state, { waypointID, overview }) => {
            var o = overview.map(el => {
                return {
                    ...el,
                    UpsellAddonItems: el.UpsellAddonItems.map(eel => {
                        if (eel.Position > 0) return eel
                        return {
                            ...eel,
                            Position: 1
                        }
                    })
                }
            })

            Vue.set(state.storeExclusionaryUpsellOverviews, waypointID, o)
        },

    },
    actions: {
        getStoreUpsellOverview: ({ commit }, waypointID) => {
            let overviewPromise = passthrough(GetUpsellOverviewRequest(waypointID)).then(res => {
                return res.data.UpsellAddonLevels
            })
            let exclusionaryPromise = passthrough(GetExclusionaryUpsellOverviewRequest(waypointID)).then(res => {
                return res.data.UpsellAddonLevels
            })
            let groupsPromise = passthrough(GetUpsellGroupsRequest(waypointID)).then(res => {
                return res.data.Groups
            })

            return Promise.all([overviewPromise, groupsPromise, exclusionaryPromise]).then(([overview, groups, exclusions]) => {
                commit('RECIEVE_STORE_UPSELL_OVERVIEW', {
                    waypointID,
                    overview,
                })
                commit('RECIEVE_STORE_UPSELL_GROUPS', {
                    waypointID,
                    groups,
                })
                commit('RECIEVE_STORE_EXCLUSIONARY_UPSELL_OVERVIEW', {
                    waypointID,
                    overview: exclusions
                })
            })
        },
        getStoreAddonOverview: ({ commit }, waypointID) => {
            let overviewPromise = passthrough(GetAddonOverviewRequest(waypointID)).then(res => {
                return res.data.UpsellAddonLevels
            })
            let exclusionaryPromise = passthrough(GetExclusionaryAddonOverviewRequest(waypointID)).then(res => {
                return res.data.UpsellAddonLevels
            })
            let groupsPromise = passthrough(GetUpsellGroupsRequest(waypointID)).then(res => {
                return res.data.Groups
            })

            return Promise.all([overviewPromise, groupsPromise, exclusionaryPromise]).then(([overview, groups, exclusions]) => {
                commit('RECIEVE_STORE_ADDON_OVERVIEW', {
                    waypointID,
                    overview,
                })
                commit('RECIEVE_STORE_UPSELL_GROUPS', {
                    waypointID,
                    groups,
                })
                commit('RECIEVE_STORE_EXCLUSIONARY_ADDON_OVERVIEW', {
                    waypointID,
                    overview: exclusions
                })
            })
        },

        getStoreUpsellitems: ({ commit }, waypointID) => {
            return passthrough(GetUpsellOverviewRequest(waypointID)).then(res => {
                commit('RECIEVE_STORE_UPSELL_OVERVIEW', {
                    waypointID,
                    overview: res.data.UpsellAddonLevels
                })
            })
        },
        updateUpsellItem: ({ commit }, { waypointID, item }) => {
            commit('SET_LOADING', true, { root: true })
            let requests = item.exclusionary ? UpdateExclusionaryUpsellItemRequests(item) : UpdateUpsellItemRequests(item)
            return Promise.all(requests.map(req => {
                return passthrough(req)
            }))
                .then(() => {
                    return passthrough(GetUpsellOverviewRequest(waypointID)).then(res => {
                        commit('RECIEVE_STORE_UPSELL_OVERVIEW', {
                            waypointID,
                            overview: res.data.UpsellAddonLevels
                        })
                    })
                })
                .finally(() => {
                    commit('SET_LOADING', false, { root: true })
                })
        },
        updateAddonItem: ({ commit }, { waypointID, item }) => {
            commit('SET_LOADING', true, { root: true })
            let requests = item.exclusionary ? UpdateExclusionaryAddonItemRequests(item) : UpdateAddonItemRequests(item)
            return Promise.all(requests.map(req => {
                return passthrough(req)
            }))
                .then(() => {
                    return passthrough(GetAddonOverviewRequest(waypointID)).then(res => {
                        commit('RECIEVE_STORE_ADDON_OVERVIEW', {
                            waypointID,
                            overview: res.data.UpsellAddonLevels
                        })
                    })
                })
                .finally(() => {
                    commit('SET_LOADING', false, { root: true })
                })
        },
        deleteUpsellItem: ({ commit }, { waypointID, targetID, exclusionary }) => {
            commit('SET_LOADING', true, { root: true })
            let request = exclusionary ? DeleteExclusionaryUpsellItem(waypointID, targetID) : DeleteUpsellItem(waypointID, targetID)
            return passthrough(request).then(res => {
                if (!exclusionary)
                    commit('RECIEVE_STORE_UPSELL_OVERVIEW', {
                        waypointID,
                        overview: res.data.UpsellAddonLevels,
                    })
                else
                    commit('RECIEVE_STORE_EXCLUSIONARY_UPSELL_OVERVIEW', {
                        waypointID,
                        overview: res.data.UpsellAddonLevels,
                    })
                return true
            }).finally(() => {
                commit('SET_LOADING', false, { root: true })
            })
        },
        deleteAddonItem: ({ commit }, { waypointID, targetID, exclusionary }) => {
            commit('SET_LOADING', true, { root: true })
            let request = exclusionary ? DeleteExclusionaryAddonItem(waypointID, targetID) : DeleteAddonItem(waypointID, targetID)
            return passthrough(request).then(res => {
                if (!exclusionary)
                    commit('RECIEVE_STORE_ADDON_OVERVIEW', {
                        waypointID,
                        overview: res.data.UpsellAddonLevels,
                    })
                else
                    commit('RECIEVE_STORE_EXCLUSIONARY_ADDON_OVERVIEW', {
                        waypointID,
                        overview: res.data.UpsellAddonLevels
                    })
                return true
            }).finally(() => {
                commit('SET_LOADING', false, { root: true })
            })
        },
        deleteUpsellGroup: ({ commit }, { waypointID, groupID }) => {
            commit('SET_LOADING', true, { root: true })
            let request = UpsellGroupDelete(waypointID, groupID)
            return passthrough(request).then(res => {
                commit('RECIEVE_STORE_UPSELL_GROUPS', {
                    waypointID,
                    groups: res.data.Groups
                })
                return true
            }).finally(() => {
                commit('SET_LOADING', false, { root: true })
            })
        },

        updateUpsellGroup: ({ commit }, { waypointID, upsellGroup }) => {
            commit('SET_LOADING', true, { root: true })
            let request = UpsellGroupUpdate(waypointID, upsellGroup)
            return passthrough(request).then(res => {
                commit('RECIEVE_STORE_UPSELL_GROUPS', {
                    waypointID,
                    groups: res.data.Groups
                })
                return true
            }).finally(() => {
                commit('SET_LOADING', false, { root: true })
            })
        },
        createUpsellGroup: ({ commit }, { waypointID, upsellGroup }) => {
            commit('SET_LOADING', true, { root: true })
            let request = UpsellGroupCreate(waypointID, upsellGroup)
            return passthrough(request).then(res => {
                commit('RECIEVE_STORE_UPSELL_GROUPS', {
                    waypointID,
                    groups: res.data.Groups
                })
                return true
            }).finally(() => {
                commit('SET_LOADING', false, { root: true })
            })
        },
    },
    getters: {
        upsellOverviewForStore: state => {
            let storeWaypointID = router.currentRoute.params.store
            if (storeWaypointID == null || storeWaypointID == undefined) return []

            let overview = state.storeUpsellOverviews[`${storeWaypointID}`]
            if (overview === undefined) return []

            return overview
        },
        exlusionaryUpsellOverviewForStore: state => {
            let storeWaypointID = router.currentRoute.params.store
            if (storeWaypointID == null || storeWaypointID == undefined) return []

            let overview = state.storeExclusionaryUpsellOverviews[`${storeWaypointID}`]
            if (overview === undefined) return []

            return overview
        },
        addonOverviewForStore: state => {
            let storeWaypointID = router.currentRoute.params.store
            if (storeWaypointID == null || storeWaypointID == undefined) return []

            let overview = state.storeAddonOverviews[`${storeWaypointID}`]
            if (overview === undefined) return []

            return overview
        },
        exclusionaryAddonOverviewForStore: state => {
            let storeWaypointID = router.currentRoute.params.store
            if (storeWaypointID == null || storeWaypointID == undefined) return []

            let overview = state.storeExclusionaryAddonOverviews[`${storeWaypointID}`]
            if (overview === undefined) return []

            return overview
        },
        upsellGroupsForStore: state => {
            let storeWaypointID = router.currentRoute.params.store
            if (storeWaypointID == null || storeWaypointID == undefined) return []

            let groups = state.storeUpsellGroups[`${storeWaypointID}`]
            if (groups === undefined) return []

            return groups
        }
    }
}
let GetUpsellOverviewRequest = (storeWaypointID) => {
    return {
        url: 'CursusHub_GetUpsellItemsForAllLevelsOfStore',
        method: 'GET',
        qs: {
            storeWaypointID,
        },
    }
}
let GetAddonOverviewRequest = (storeWaypointID) => {
    return {
        url: 'CursusHub_GetAddonItemsForAllLevelsOfStore',
        method: 'GET',
        qs: {
            storeWaypointID,
        },
    }
}

let GetExclusionaryUpsellOverviewRequest = (storeWaypointID) => {
    return {
        url: 'CursusHub_GetExclusionaryUpsellItemsForAllLevelsOfStore',
        method: 'GET',
        qs: {
            storeWaypointID,
        },
    }
}

let GetExclusionaryAddonOverviewRequest = (storeWaypointID) => {
    return {
        url: 'CursusHub_GetExclusionaryAddonItemsForAllLevelsOfStore',
        method: 'GET',
        qs: {
            storeWaypointID,
        },
    }
}

let DeleteUpsellItem = (storeWaypointID, targetID) => {
    return {
        url: 'CursusHub_DeleteUpsellItem',
        method: 'GET',
        qs: {
            storeWaypointID,
            targetID,
        },
    }
}

let DeleteExclusionaryUpsellItem = (storeWaypointID, targetID) => {
    return {
        url: 'CursusHub_DeleteExclusionaryUpsellItem',
        method: 'GET',
        qs: {
            storeWaypointID,
            targetID,
        },
    }
}

let DeleteExclusionaryAddonItem = (storeWaypointID, targetID) => {
    return {
        url: 'CursusHub_DeleteExclusionaryAddonItem',
        method: 'GET',
        qs: {
            storeWaypointID,
            targetID,
        },
    }
}

let DeleteAddonItem = (storeWaypointID, targetID) => {
    return {
        url: 'CursusHub_DeleteAddonItem',
        method: 'GET',
        qs: {
            storeWaypointID,
            targetID,
        },
    }
}
//GetUpsellItemsForItem
let MapUpsellLevelToRequestURLAndQuery = (level) => {
    var idName = ''
    switch (level.LevelID) {
    case 0:
        idName = "storeWaypointID"
        break;
    case 1:
        idName = "categoryID"
        break;
    case 2:
        idName = "inventoryItemID"
        break
    default:
        return { url: '', qs: {} };
    }
    let qs = {}
    qs[idName] = level.TargetID
    return {
        url: `CursusHub_SetUpsellItemsFor${_.startCase(_.toLower(level.LevelName))}`,
        qs,
    }
}

let MapAddonLevelToRequestURLAndQuery = (level) => {
    var idName = ''
    switch (level.LevelID) {
    case 0:
        idName = "storeWaypointID"
        break;
    case 1:
        idName = "categoryID"
        break;
    case 2:
        idName = "inventoryItemID"
        break
    default:
        return { url: '', qs: {} };
    }
    let qs = {}
    qs[idName] = level.TargetID
    return {
        url: `CursusHub_SetAddonItemsFor${_.startCase(_.toLower(level.LevelName))}`,
        qs,
    }
}
//https://grabmobilestagev2.com/cursus/CursusHub_SetUpsellItemsForItem?kobp=grabportal&email=jarod@getgrab.com
//&inventoryItemID=221816&position=1&delimitedUpsellItemIDs=123456,543210,432112

let UpsellItemPositionQueryString = (positionNumber, positionItems) => {
    return {
        position: positionNumber,
        delimitedUpsellItemIDs: positionItems.map(el => el.InventoryItemID).join(',')
    }
}

let AddonItemPositionQueryString = (positionNumber, positionItems) => {
    return {
        position: positionNumber,
        delimitedAddonItemIDs: positionItems.map(el => el.InventoryItemID).join(',')
    }
}

let UpdateUpsellItemRequests = (item) => {
    return Object.entries(item.positions).map(([positionNumber, positionItems]) => {
        let { url, qs } = MapUpsellLevelToRequestURLAndQuery(item)
        return {
            url,
            method: 'GET',
            qs: {
                ...qs,
                ...UpsellItemPositionQueryString(positionNumber, positionItems)
            }
        }
    })
}

let UpdateExclusionaryAddonItemRequests = (item) => {
    let polePosition = Object.values(item.positions)[0]
    if (item.LevelID == 1) {
        return [{
            url: 'CursusHub_SetExclusionaryAddonItemsForCategory',
            method: 'GET',
            qs: {
                categoryID: item.TargetID,
                delimitedExclusionaryAddonItemIDs: polePosition.map(el => el.InventoryItemID).join(',')
            }
        }]
    }
    else if (item.LevelID == 2) {
        return [{
            url: 'CursusHub_SetExclusionaryAddonItemsForItem',
            method: 'GET',
            qs: {
                inventoryItemID: item.TargetID,
                delimitedExclusionaryAddonItemIDs: polePosition.map(el => el.InventoryItemID).join(',')
            }
        }]
    }
}

let UpdateExclusionaryUpsellItemRequests = (item) => {
    let polePosition = Object.values(item.positions)[0]
    if (item.LevelID == 1) {
        return [{
            url: 'CursusHub_SetExclusionaryUpsellItemsForCategory',
            method: 'GET',
            qs: {
                categoryID: item.TargetID,
                delimitedExclusionaryUpsellItemIDs: polePosition.map(el => el.InventoryItemID).join(',')
            }
        }]
    }
    else if (item.LevelID == 2) {
        return [{
            url: 'CursusHub_SetExclusionaryUpsellItemsForItem',
            method: 'GET',
            qs: {
                inventoryItemID: item.TargetID,
                delimitedExclusionaryUpsellItemIDs: polePosition.map(el => el.InventoryItemID).join(',')
            }
        }]
    }
}
let UpdateAddonItemRequests = (item) => {
    return Object.entries(item.positions).map(([positionNumber, positionItems]) => {
        let { url, qs } = MapAddonLevelToRequestURLAndQuery(item)
        return {
            url,
            method: 'GET',
            qs: {
                ...qs,
                ...AddonItemPositionQueryString(positionNumber, positionItems)
            }
        }
    })
}

//CursusHub_GetUpsellAddonGroupsForStore?kobp=grabportal&email=jarod@getgrab.com&storewaypointid=20000&enabledOnly=true
let GetUpsellGroupsRequest = (storeWaypointID) => {
    return {
        url: 'CursusHub_GetUpsellAddonGroupsForStore',
        method: 'GET',
        qs: {
            storeWaypointID,
            enabledOnly: false,
        },
    }
}
//https://grabmobilestagev2.com/cursus/CursusHub_UpdateUpsellAddonGroupForStore?kobp=grabportal&email=jarod@getgrab.com
//&storewaypointid=20000&groupID=5&groupName=Test%20Group%202&enabled=true&delimitedItemIDs=221790
let UpsellGroupUpdate = (storeWaypointID, group) => {
    return {
        url: 'CursusHub_UpdateUpsellAddonGroupForStore',
        method: 'GET',
        qs: {
            storeWaypointID,
            groupID: group.GroupID,
            groupName: group.GroupName,
            enabled: group.Enabled,
            delimitedItemIDs: group.items.map(el => el.id).join(',')
        },
    }
}
//https://grabmobilestagev2.com/cursus/CursusHub_DeleteUpsellAddonGroupForStore?kobp=grabportal&email=jarod@getgrab.com
//&storewaypointid=20000&groupID=5

let UpsellGroupDelete = (storeWaypointID, groupID) => {
    return {
        url: 'CursusHub_DeleteUpsellAddonGroupForStore',
        method: 'GET',
        qs: {
            storeWaypointID,
            groupID,
        },
    }
}

//https://grabmobilestagev2.com/cursus/CursusHub_CreateUpsellAddonGroupForStore?kobp=grabportal&email=jarod@getgrab.com
//&storewaypointid=20000&groupName=Test%20Group%202&enabled=true&delimitedItemIDs=221796

let UpsellGroupCreate = (storeWaypointID, group) => {
    return {
        url: 'CursusHub_CreateUpsellAddonGroupForStore',
        method: 'GET',
        qs: {
            storeWaypointID,
            groupName: group.GroupName,
            enabled: group.Enabled,
            delimitedItemIDs: group.items.map(el => el.id).join(',')
        },
    }
}

