import { authHttp, passthrough } from '../http'
import _ from 'lodash'

export default {
    namespaced: true,
    state: {
        airports: [],
        locations: []
    },
    mutations: {
        RECIEVE_AIRPORTS: (state, {airports, baseLocations}) => {
            state.airports = airports
            state.locations = baseLocations
        }
    },
    actions: {
        getAirports: ({commit, state}) => {
            if (state.airports.length > 0 && state.locations.length > 0) return
            let request = AriportsRequest()
            return passthrough(request).then((res) => {
                var airports = res.data.airports
                var baseLocations = res.data.locations

                baseLocations = _.sortBy(baseLocations, l => l.name)

                commit('RECIEVE_AIRPORTS', {
                    airports: Object.freeze(airports),
                    baseLocations: Object.freeze(baseLocations),
                })
            }).catch(err => {
                console.error(err)
            })            
        }
    },
    getters: {
        airports: (state, getters, rootState) => {
            if (rootState.user == undefined) return state.airports
            if (rootState.user.email == undefined) return state.airports
            if (rootState.user.email.includes('@getgrab.com') || rootState.user.email.includes('@servy.us')) return state.airports
            let abbrs = rootState.user.storeAccess.map(el => el.airportIdent)

            return state.airports.filter(el => abbrs.includes(el.airportIdent))
        },
        locations: (state, getters, rootState) => {
            if (rootState.user == undefined) return state.locations
            if (rootState.user.email == undefined) return state.locations

            if (rootState.user.email.includes('@getgrab.com') || rootState.user.email.includes('@servy.us')) return state.locations
            let waypointIDs = rootState.user.storeAccess.map(el => el.storeWaypointID)
            return state.locations.filter(el => waypointIDs.includes(el.waypointID))
        }
    }
}

let AriportsRequest = () => {
    return {
        url: 'CursusHub_GetAirportInfo',
        method: 'GET',
    }

}