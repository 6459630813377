import {OperationStatus} from "helpers/common";

export default {
    user: false,
    pageTitle: '',
    editingModalEnabled: false,
    editableItems: [],
    itemsExpanded: false,
    activeStore: {},
    activeLiveStore: {},
    activeProduct: {},
    loading: false,
    loadingCount: 0,
    toastMessage: false,
    productCategories: [],
    imageServer: '',
    products: [],
    inventoryPos: {},
    modSets: [],
    posItems: [],
    posMods: [],
    storePlatforms: [],
    inventoryItemIDsWithDayPartingEnabled: [],
    reloadRequestId: false,
    reloadItemsLoaded: false,
    reloadItemsToLoad: false,
    reloadRequestComplete: false,
    reloadMap: {},
    dev: false,
    modificationEmailSent: false,
    menuIntegrity: {
        0: {
            status: OperationStatus.Unknown,
            data: null,
            errors: [],
            lastUpdatedOn: null,
        }
    }
}
