import { passthrough } from '../http'
import _ from 'lodash'
import router from '../routes'

export default {
    namespaced: true,
    state: {
        airports: [],
        deliveryPartners: []
    },
    mutations: {
        SET_AIRPORTS: (state, airports) => {
            state.airports = airports
        },
        SET_DELIVERY_PARTNERS: (state, partners) => {
            state.deliveryPartners = partners
        },
        CLEAR: (state) => {
            state.airports = []
        }
    },
    actions: {
        getDeliveryAirportsForDeliveryPartner: ({ commit }, payload) => {
            commit('SET_LOADING', true, { root: true })
            let request = GetAirportsRequest(payload)
            passthrough(request).then((res) => {
                commit('SET_LOADING', false, { root: true })
                commit('SET_AIRPORTS', res.data.items)
            })
        },
        setDeliveryEnabled: ({ commit }, payload) => {
            commit('SET_LOADING', true, { root: true })
            let request = SetDeliveryEnabledRequest(payload)
            passthrough(request).then((res) => {
                commit('SET_LOADING', false, { root: true })
                commit('SET_AIRPORTS', res.data.items)
            })
        },
        getDeliveryPartners: ({ commit}, env) => {
            commit('SET_LOADING', true, { root: true })
            let request = GetDeliveryPartnersRequest(env)
            passthrough(request).then((res) => {
                commit('SET_LOADING', false, { root: true })
                commit('SET_DELIVERY_PARTNERS', res.data)
            })
        },
        clearDeliveryStore: ({commit}) => {
            commit('CLEAR', true)
        }
    },
    getters: {
        deliveryAirports: state => {
            return state.airports
        },
        deliveryPartners: state => {
            return state.deliveryPartners
        }
    }
}

let GetDeliveryPartnersRequest = (env) => {
    return {
        url: 'CursusHub_GetDeliveryPartners',
        method: 'GET',
        qa: {env}
    }
}
let GetAirportsRequest = (payload) => {
    return {
        url: 'CursusHub_GetAirportDeliveryParterInfo',
        method: 'GET',
        qs: {
            deliveryPartnerShortCode: payload.deliveryPartnerShortCode,
            env: payload.env
        },
    }
}

let SetDeliveryEnabledRequest = (payload) => {
    return {
        url: 'CursusHub_SetAirportDeliveryStatus',
        method: 'GET',
        qs: payload
    }
}