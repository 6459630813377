import { authHttp, passthrough } from '../http'

export default {
    namespaced: true,
    state: {
        reloadInformation: {}
    },
    mutations: {
        START_POS_RELOAD: (state, { storeWaypointID, requestID }) => {
            state.reloadInformation[storeWaypointID] = {
                requestID,
            }
        },
        RECIEVE_POS_RELOAD_PROGRESS: (state, {storeWaypointID, data}) => {
            state.reloadInformation[storeWaypointID] = data
        },
        COMPLETE_POS_RELOAD: (state, storeWaypointID) => {
            delete state.reloadInformation[storeWaypointID]
        }
    },
    actions: {
        reloadPos: ({ state, commit }, storeWaypointID) => {

            if (state[storeWaypointID] != undefined && state[storeWaypointID].requestID) return;

            commit('SET_LOADING', true, { root: true })

            return authHttp.post('/api/store/pos/reload', { storeWaypointID })
                .then(({ data }) => {
                    commit('START_POS_RELOAD', {storeWaypointID, requestID: data.requestID})
                })
                .catch(console.error).finally(() => {
                    commit('SET_LOADING', false, { root: true })
                })

        },
        checkReloadProgress: ({ state, commit }, storeWaypointID) => {
            if (state.reloadInformation[storeWaypointID] == undefined) return
            const requestID = state.reloadInformation[storeWaypointID].requestID

            return authHttp.get('/api/store/pos/processes/' + requestID)
                .then(({ data }) => {
                    commit('RECIEVE_POS_RELOAD_PROGRESS', {storeWaypointID, data})
                    return data
                })
                .catch(console.error)
        },
        completePOSReload: ({ commit }, storeWaypointID) => {
            commit('COMPLETE_POS_RELOAD', storeWaypointID)
        },
    },
    getters: {
        reloadInformation: state => {
            return state.reloadInformation
        },
    }
}