<template>
    <transition name="loading-appear">
        <div class="loading">
            <div class="loading__indicator"/>
        </div>
    </transition>
</template>
<script>
export default {
    name: 'Loading',
}
</script>
<style lang="scss" scoped>
    @import '~scss/variables';
    @import '~scss/mixins';

    .loading {
        position: fixed;
        z-index: zi(modal);
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: $modal_background;

        &__indicator {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 50px;
            height: 50px;
            background-color: $brand_color;
            border-radius: 50%;
            box-sizing: content-box;

            &:after {
                content: '';
                position: absolute;
                border-radius: 50%;
                top: 50%;
                left: 50%;
                border: 0px solid $grey_light_2;
                transform: translate(-50%, -50%);
                animation: loading $transition_loading ease-out forwards infinite;
            }
        }
    }

    @keyframes loading {
        0% {
            border: 0px solid $grey_light_2;
        }
        20% {
            border: 8px solid $grey_light_2;
            width: 0%;
            height: 0%;
        }
        100% {
            border: 8px solid $grey_light_2;
            width: 100%;
            height: 100%
        }
    }
    .slide-fade-enter-active, .slide-fade-leave-active {
        transition: opactity $transition_short;
    }
    .slide-fade-enter, .slide-fade-leave-to {
        opacity: 0;
    }
</style>
