import './scss/_main.scss';

import Vue from 'vue';
import { toast } from 'mixins';
import VueVirtualScroller from 'vue-virtual-scroller';
import Vuebar from 'vuebar';
import store from './store/store';
import router from './routes';
import app from './components/app.vue';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css';
require('intersection-observer');
import CKEditor from '@ckeditor/ckeditor5-vue2';
import VColorInput from 'vuetify-color-input';


Vue.config.productionTip = false; // Removes development mode console warning.
Vue.config.performance = true;

Vue.mixin(toast);
Vue.use(VueVirtualScroller);
Vue.use(Vuebar);
Vue.use(Vuetify);
Vue.use(CKEditor);
Vue.component(VColorInput.name, VColorInput);

new Vue({
    vuetify: new Vuetify({
        theme: {
            themes: {
                light: {
                    primary: '#93BD20',
                },
            },
        },
        icons: {
            iconfont: 'mdi',
        },
    }),
    router,
    store,
    components: {
        app,
    },
    template: '<app />',
}).$mount('#app');
