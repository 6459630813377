<template>
    <div class="modal">
        <slot/>
    </div>
</template>

<script>
export default {
    name: 'Modal',

    // Hooks

    mounted() {
        document.body.style.overflow = 'hidden'
    },

    beforeDestroy() {
        document.body.style.overflow = 'auto'
    },
}
</script>

<style lang="scss">
@import '~scss/mixins';
@import '~scss/variables';

.modal {
    @include depth;
    background-color: #fff;
    border-radius: $baseline;
}
</style>
