export default {
    state: {
        accessTokenExpired: false,
    },
    mutations: {
        SET_ACCESS_TOKEN_EXPIRED: (state, expired) => {
            state.accessTokenExpired = expired
        }
    },
    actions: {
        expireAccessToken: ({ commit }) => {
            commit('SET_ACCESS_TOKEN_EXPIRED', true)
        },
        unExpireAccessToken: ({ commit }) => {
            commit('SET_ACCESS_TOKEN_EXPIRED', false)
        },
    },
    getters: {
        accessTokenExpired: state => {
            return state.accessTokenExpired
        },
    }
}