var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.leaveAction
        ? _c(
            "editing-modal",
            { attrs: { size: "sm" } },
            [
              _c(
                "alert-dialog",
                {
                  attrs: {
                    onExit: _vm.onExit,
                    actions: _vm.actions,
                    title: "Unsaved Options",
                  },
                },
                [
                  _vm._v(
                    " You have unsaved options. Are you sure you want to leave this page? "
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }