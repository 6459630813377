<template>
    <div 
        class="action-button d-flex justify-content-center align-items-center"
        @click="action">
        <span
            :class="[`action-button__text--${type}`]"
            class="action-button__text"><slot/></span>

        <span
            v-if="type && !noIcon"
            :class="[`action-button__img--${type}`, {'block block-xs--xxs-left': hasText}]"
            class="action-button__img height-xs--inherit block"
        />
    </div>
</template>

<script>
export default {
    name: 'ActionButton',
    props: {
        type: String,
        action: {
            type: Function,
            required: true,
        },
        noIcon: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        hasText() {
            return this.$slots.default
        },
    },
}
</script>

<style lang="scss">
@import '~scss/variables';
@import '~scss/mixins';

.action-button {
    .action-button__text {
        flex-grow: 0 !important;
    }
    .action-button__img {
        flex-grow: 0 !important;
    }
}
.action-button {
    position: relative;
    padding: spacing(xs);
    text-align: center;
    cursor: pointer;
    transition: background-color $transition_short;
    
    &:after {
        content: '';
        position: absolute;
        right: -1px;
        top: 50%;
        display: block;
        width: 2px;
        height: 50%;
        transform: translateY(-50%);
        background-color: $border_light_grey;
    }

    &:last-of-type {
        &:after {
            display: none;
        }
    }

    &:hover {
        background-color: $grey_light_2;
    }

    &__text {
        &--faded {
            color: $grey_mid_1;
        }

        &--next-green, &--proceed-green, &--green {
            color: $brand_color;
        }

        &--remove-red, &--red {
            color: $delete_red;
        }
    }

    &__img {
        width: spacing(sm);
        background-position: right center;
        margin-top: -2px;
        &--cancel {
            @include background-image('', 'cancel_logo.svg');
        }

        &--proceed {
            @include background-image('', 'proceed_logo.svg');
        }

        &--proceed-green {
            @include background-image('', 'proceed-logo-green.svg');
        }

        &--edit {
            @include background-image('', 'edit_logo.svg');
        }

        &--expand {
            @include background-image('', 'expand_logo.svg');
        }

        &--collapse {
            @include background-image('', 'collapse_logo.svg');
        }

        &--delete {
            @include background-image('', 'delete.svg');
        }

        &--duplicate {
            @include background-image('', 'duplicate_logo.svg');
        }

        &--edit-multi {
            @include background-image('', 'edit_multi_logo.svg');
        }

        &--add-new {
            @include background-image('', 'plus.svg');
            background-size: 15px 19px;
        }

        &--disable {
            @include background-image('', 'disable_logo.svg');
        }

        &--remove-red {
            @include background-image('', 'trash_red.svg');
        }

        &--next {
            @include background-image('', 'next-arrow.svg');
        }

        &--next-green {
            @include background-image('', 'delimiter-green.svg');
            background-size: 12px 15px;
        }
    }
}
</style>
