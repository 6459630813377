const productTransform = require('./product')

exports.commit = products => category => {
    category.children = products
        .filter(product => {
            return product.inventoryTitles[0].inventoryTitleID === category.inventoryTitleID
        }).sort((a, b) => {
            const aOrder = Number(a.inventoryOrder)
            const bOrder = Number(b.inventoryOrder)

            if (aOrder > bOrder) {
                return 1
            }

            if (aOrder < bOrder) {
                return -1
            }

            return 0
        })

    category.children = category.children.map(productTransform.commit)

    category.title = category.inventoryTitleDescription

    return category
}
