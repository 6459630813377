import { authHttp, passthrough } from '../http'
import _ from 'lodash'
import router from '../routes'
import clone from 'clone'

const STORE_NOTE_TYPE = "STORE"
const POS_NOTE_TYPE = "POS"

export default {
    namespaced: true,
    state: {
        storeNotes: [],
        posNotes: [],
    },
    mutations: {
        RECIEVE_NOTES: (state, payload) => {
            if (payload.type == STORE_NOTE_TYPE) {
                state.storeNotes = payload.notes
            } else if (payload.type == POS_NOTE_TYPE) {
                state.posNotes = payload.notes
            }
        },
    },
    actions: {
        getNotes: ({ commit }, {storeWaypointID, noteType}) => {
            commit('SET_LOADING', true, { root: true })
            let request = GetNotesRequest(storeWaypointID, noteType)
            return passthrough(request).then(res => {
                commit('RECIEVE_NOTES', {notes: res.data, type: noteType})
                return res.data
            }).finally(() => {
                commit('SET_LOADING', false, { root: true })
            })
        },
        createNote: ({ commit }, {storeWaypointID, noteType, note}) => {
            commit('SET_LOADING', true, { root: true })
            let request = CreateNoteRequest(storeWaypointID, noteType, note)
            return passthrough(request).then(res => {
                commit('RECIEVE_NOTES', {notes: res.data, type: noteType})
                return res.data
            }).finally(() => {
                commit('SET_LOADING', false, { root: true })
            })
        },
        updateNote: ({ commit }, {storeWaypointID, noteType, note}) => {
            commit('SET_LOADING', true, { root: true })
            let request = UpdateNoteRequest(storeWaypointID, noteType, note)
            return passthrough(request).then(res => {
                commit('RECIEVE_NOTES', {notes: res.data, type: noteType})
                return res.data
            }).finally(() => {
                commit('SET_LOADING', false, { root: true })
            })
        },
        deleteNote: ({ commit }, {storeWaypointID, noteType, noteID }) => {
            commit('SET_LOADING', true, { root: true })
            let request = DeleteNoteRequest(storeWaypointID, noteType, noteID)
            return passthrough(request).then(res => {
                commit('RECIEVE_NOTES', {notes: res.data, type: noteType})
                return res.data
            }).finally(() => {
                commit('SET_LOADING', false, { root: true })
            })
        },
    },
    getters: {
        storeNotes: state => {
            return state.storeNotes
        },
        posNotes: state => {
            return state.posNotes
        },
    }
}

let GetNotesRequest = (storeWaypointID, type) => {
    return {
        url: 'CursusHub_GetStoreNotes',
        method: 'GET',
        qs: {
            storeWaypointID,
            type,
        }
    }
}

let UpdateNoteRequest = (storeWaypointID, type, note) => {
    return {
        url: 'CursusHub_UpdateStoreNote',
        method: 'PUT',
        qs: {
            storeWaypointID,
            type,
        },
        body: note
    }
}

let CreateNoteRequest = (storeWaypointID, type, note) => {
    return {
        url: 'CursusHub_CreateStoreNote',
        method: 'POST',
        qs: {
            storeWaypointID,
            type,
        },
        body: note
    }
}

let DeleteNoteRequest = (storeWaypointID, type, noteID) => {
    return {
        url: 'CursusHub_DeleteStoreNote',
        method: 'DELETE',
        qs: {
            storeWaypointID,
            storeNoteID: noteID,
            type,
        },
    }
}


