const modGroupTransform = require('./mod_groups')
const subTransform = require('./sub')

exports.commit = product => {
    product.taxes.forEach(tax => {
        product['taxRate' + tax.Description] = tax.Amount
    })

    product.id = product.inventoryItemID
    product.sku = product.inventoryItemSubs[0].sku
    product.pendingDelete = false
    product.bRemoveInventoryItemBeforeCreation = false

    product.modGroups = product.inventoryMainOptionChoice.choices
        .map(el => {
            return Object.assign({}, el, {
                required: true,
                was: 'choice',
            })
        })
        .concat(
            product.inventoryMainOptionChoice.options
                .map(el => {
                    return Object.assign({}, el, {required: false, was: 'option'})
                })
        )
        .map(modGroupTransform.commit)

    product.inventoryMainOptionChoice = {
        Choices: product.inventoryMainOptionChoice.choices,
        Options: product.inventoryMainOptionChoice.options,
    }

    product.inventoryItemSubs = product.inventoryItemSubs
        .map(subTransform.commit(product))

    return product
}
