var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row no-gutters" }, [
    _c("div", { staticClass: "alert-dialog col-12" }, [
      _vm.title || _vm.onExit
        ? _c("div", { staticClass: "alert-dialog__header row no-gutters" }, [
            _vm.title
              ? _c("div", { staticClass: "alert-dialog__title col-10" }, [
                  _vm._v(" " + _vm._s(_vm.title) + " "),
                ])
              : _vm._e(),
            _vm.onExit
              ? _c(
                  "div",
                  {
                    staticClass: "alert-dialog__exit col",
                    on: { click: _vm.onExit },
                  },
                  [_c("span", { staticClass: "icon icon--close" })]
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _c("div", { staticClass: "row no-gutters" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "div",
            { staticClass: "alert-dialog__body" },
            [_vm._t("default")],
            2
          ),
        ]),
      ]),
      _vm.actions && _vm.actions.length
        ? _c(
            "div",
            { staticClass: "alert-dialog__buttons row no-gutters" },
            _vm._l(_vm.actions, function (action) {
              return _c(
                "action-button",
                {
                  key: Math.random(),
                  staticClass: "col d-flex justify-content-center",
                  attrs: { type: action.type, action: action.run },
                },
                [_vm._v(_vm._s(action.display))]
              )
            }),
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }