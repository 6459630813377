import clone from "clone"
import Vue from "vue"
import {OperationStatus} from "helpers/common";
import moment from 'moment';
export default {
    RECIEVE_ACTIVE_STORE: (state, data) => {
        state.activeStore = data
        state.storePlatforms = data.platform
    },
    RECIEVE_ACTIVE_LIVE_STORE: (state, data) => {
        state.activeLiveStore = data
    },
    RECIEVE_ACTIVE_PRODUCT: (state, data) => {
        state.activeProduct = data
    },
    RECIEVE_PRODUCT_CATEGORIES: (state, data) => {
        state.productCategories = data
    },
    RECIEVE_PRODUCTS: (state, items) => {
        state.activeStore.inventoryItemMains = items
    },
    SET_EDITABLE_ITEM: (state, item) => {
        state.editableItems.push(item)
    },
    REMOVE_EDITABLE_ITEM: (state, id) => {
        state.editableItems = state.editableItems.filter(el => {
            return el.id !== id
        })
    },
    CLEAR_EDITABLE_ITEMS: (state) => {
        state.editableItems = []
    },
    ENABLE_EDITING_MODAL: (state) => {
        state.editingModalEnabled = true
    },
    DISABLE_EDITING_MODAL: (state) => {
        state.editingModalEnabled = false
    },
    EXPAND_ALL_ITEMS: (state) => {
        state.itemsExpanded = true
    },
    COLLAPSE_ALL_ITEMS: (state) => {
        state.itemsExpanded = false
    },
    RECIEVE_MOD_SETS: (state, items) => {
        state.modSets = items
    },
    RECIEVE_MOD_GROUPS: (state, items) => {
        state.modGroups = items
    },
    RECIEVE_MODS: (state, items) => {
        state.mods = items
    },
    SAVE_UPSELL_ITEM_PRODUCTS: (state, {id, products}) => {
        state.upsellItems = state.upsellItems.map(el => {
            if (el.id === id) {
                el.products = products
            }

            return el
        })
    },
    SET_TOAST: (state, toast) => {
        state.toastMessage = toast
    },
    CLEAR_TOAST: (state) => {
        state.toastMessage = {}
    },
    SET_LOADING: (state, loading) => {
        if (loading) {
            state.loadingCount = state.loadingCount + 1
            state.loading = true
        } else {
            state.loadingCount = Math.max(0,state.loadingCount -1)
            if (state.loadingCount == 0) state.loading = false
        }
    },
    CLEAR_LOADING: (state) => {
        state.loadingCount = 0
        state.loading = false
    },
    SET_POS_ITEMS: (state, items) => {
        state.posItems = items
    },
    SET_POS_MODS: (state, mods) => {
        state.posMods = mods
    },
    SET_POS_RELOAD_REQUEST_ID: (state, {id, storeWaypointID}) => {
        let reloadInfo = {}
        reloadInfo.reloadRequestID = id
        Vue.set(state.reloadMap, storeWaypointID, reloadInfo)
    },
    SET_POS_RELOAD_INFO: (state, {data, storeWaypointID}) => {
        var reloadInfo = clone(state.reloadMap[storeWaypointID])
        reloadInfo = {
            ...reloadInfo,
            ...data
        }
        Vue.set(state.reloadMap, storeWaypointID, reloadInfo)
    },
    CLEAR_POS_RELOAD: (state, storeWaypointID) => {
        Vue.delete(state.reloadMap, storeWaypointID)
    },
    RECIEVE_IMAGE_SERVER: (state, server) => {
        state.imageServer = server
    },
    RECIEVE_USER: (state, user) => {
        state.user = user
    },
    RECIEVE_INVENTORY_POS: (state, data) => {
        state.inventoryPos = data
    },
    SET_MENU_MODIFICATION_SENT: (state, waypointID) => {
        state.modificationEmailSent = waypointID
    },
    CHECK_MENU_INTEGRITY_IN_PROGRESS: (state, waypointID) => {
        if (!state.menuIntegrity[waypointID]) {
            Vue.set(state.menuIntegrity, waypointID, {
                status: OperationStatus.Unknown,
                errors: [],
                data: null,
                lastUpdatedOn: null,
            });
        }
        state.menuIntegrity[waypointID].status = OperationStatus.InProgress;
        state.menuIntegrity[waypointID].errors = [];
    },
    CHECK_MENU_INTEGRITY_SUCCESS: (state, {waypointID, payload}) => {
        state.menuIntegrity[waypointID].status = OperationStatus.Succeeded;
        state.menuIntegrity[waypointID].lastUpdatedOn = moment();
        state.menuIntegrity[waypointID].data = payload;
    },
    CHECK_MENU_INTEGRITY_FAILED: (state, {waypointID, errors}) => {
        state.menuIntegrity[waypointID].status = OperationStatus.Failed;
        state.menuIntegrity[waypointID].errors = errors;
    },
    SET_INVENTORYITEMIDS_WITHDAYPARTING: (state, payload) => {
        state.inventoryItemIDsWithDayPartingEnabled = payload
    }
}
