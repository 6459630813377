import { passthrough } from '@/http';
import {OperationStatus} from "helpers/common";
import Vue from 'vue';
import router from "@/routes";

const getLabelById = (obj, id) => _.chain(obj)
    .entries()
    .filter(([key,]) => key === id.toString())
    .map(([_, value]) => value)
    .first()
    .value() || (id === 0 ? 'All' : id.toString());

function getTemplateEditRequest(template, waypointId) {
    if (template.UsingDefault) {
        return CreateMessageTemplateRequest(waypointId, process.env.VUE_APP_KOBP, template);
    } else {
        return EditMessageTemplateRequest(waypointId, process.env.VUE_APP_KOBP, template);
    }
}

const editTemplateErrorMessage = 'Unexpected error occurred when saving template, please try again or contact the support';

export default {
    namespaced: true,
    state: {
        customerSmsTemplates: {
            status: OperationStatus.Unknown,
            errors: [],
            data: [],
        },
        templateMetadata: {
            status: OperationStatus.Unknown,
            errors: [],
            data: {
                platformTypes: {},
                eventTypes: {},
                orderTypes: {},
            },
        },
        templateTags: {
            status: OperationStatus.Unknown,
            errors: [],
            data: [],
        },
        editStatus: {
            [-1]: {
                status: OperationStatus.Unknown,
                errors: [],
            }
        },
        deleteStatus: {
            [-1]: {
                status: OperationStatus.Unknown,
                errors: [],
            }
        },
    },
    mutations: {
        SET_TEMPLATE_METADATA_LOADING(state) {
            state.templateMetadata.status = OperationStatus.InProgress;
            state.templateMetadata.errors = [];
        },
        SET_TEMPLATE_METADATA_SUCCESS(state, data) {
            const {platformTypes, eventTypes, orderTypes} = data;
            state.templateMetadata.data.platformTypes = platformTypes;
            state.templateMetadata.data.eventTypes = eventTypes;
            state.templateMetadata.data.orderTypes = orderTypes;
            state.templateMetadata.status = OperationStatus.Succeeded;
        },
        SET_TEMPLATE_METADATA_ERROR(state, errors) {
            state.templateMetadata.status = OperationStatus.Failed;
            state.templateMetadata.errors = errors;
        },
        SET_CUSTOMER_SMS_TEMPLATES_LOADING(state) {
            state.customerSmsTemplates.status = OperationStatus.InProgress;
            state.customerSmsTemplates.errors = [];
        },
        SET_CUSTOMER_SMS_TEMPLATES_SUCCESS(state, data) {
            state.customerSmsTemplates.status = OperationStatus.Succeeded;
            Vue.set(state.customerSmsTemplates, 'data', data);
        },
        SET_CUSTOMER_SMS_TEMPLATES_ERROR(state, errors) {
            state.customerSmsTemplates.status = OperationStatus.Failed;
            state.customerSmsTemplates.errors = errors;
        },
        SET_TEMPLATE_TAGS_LOADING(state) {
            state.templateTags.status = OperationStatus.InProgress;
            state.templateTags.errors = [];
        },
        SET_TEMPLATE_TAGS_SUCCESS(state, data) {
            state.templateTags.status = OperationStatus.Succeeded;
            Vue.set(state.templateTags, 'data', data);
        },
        SET_TEMPLATE_TAGS_ERROR(state, errors) {
            state.templateTags.status = OperationStatus.Failed;
            state.templateTags.errors = errors;
        },
        EDIT_TEMPLATE_LOADING(state, id) {
            if (!state.editStatus[id]) {
                Vue.set(state.editStatus, id, {
                    status: OperationStatus.InProgress,
                    errors: [],
                })
            } else {
                Vue.set(state.editStatus[id], 'status', OperationStatus.InProgress);
                state.editStatus[id].errors = [];
            }
        },
        EDIT_TEMPLATE_ERROR(state, {id, errors}) {
            state.editStatus[id].status = OperationStatus.Failed;
            state.editStatus[id].errors = errors;
        },
        EDIT_TEMPLATE_SUCCESS(state, {id, template}) {
            Vue.set(state.editStatus[id], 'status', OperationStatus.Succeeded);
            state.customerSmsTemplates.data = state
                .customerSmsTemplates
                .data
                .map(x => (x.TemplateID === id ? template : x));
        },
        DELETE_TEMPLATE_LOADING(state, {id}) {
            if (!state.deleteStatus[id]) {
                Vue.set(state.deleteStatus, id, {
                    status: OperationStatus.InProgress,
                    errors: [],
                })
            } else {
                Vue.set(state.deleteStatus[id], 'status', OperationStatus.InProgress);
                state.deleteStatus[id].errors = [];
            }
        },
        DELETE_TEMPLATE_ERROR(state, {id, errors}) {
            state.deleteStatus[id].status = OperationStatus.Failed;
            state.deleteStatus[id].errors = errors;
        },
        DELETE_TEMPLATE_SUCCESS(state, {id}) {
            state.deleteStatus[id].status = OperationStatus.Succeeded;
        },
    },
    actions: {
        async getMessageTemplateMetaData({ commit, state }) {
            try {
                commit('SET_TEMPLATE_METADATA_LOADING');
                const request = GetTemplateMetadataRequest();
                const { data } = await passthrough(request);

                commit('SET_TEMPLATE_METADATA_SUCCESS', data);
            } catch (e) {
                commit('SET_TEMPLATE_METADATA_ERROR', ['Unexpected error during fetch of Template metadata']);
                console.error(e);
            }
        },
        async getCustomerSMSMessageTemplates({commit, state}, waypointId) {
            try {
                commit('SET_CUSTOMER_SMS_TEMPLATES_LOADING');
                if (!waypointId) {
                    commit('SET_CUSTOMER_SMS_TEMPLATES_SUCCESS', []);
                    return;
                }
                const request = GetTemplateMessagesRequest(waypointId);
                const { data } = await passthrough(request);
                commit('SET_CUSTOMER_SMS_TEMPLATES_SUCCESS', data);
            } catch (e) {
                commit('SET_CUSTOMER_SMS_TEMPLATES_ERROR', ['Unexpected error during fetch of Templates']);
                console.error(e);
            }
        },
        async getCustomerSMSMessageTemplateTags({commit, state, rootState }) {
            try {
                commit('SET_TEMPLATE_TAGS_LOADING');
                const request = GetMessageTemplateTagsRequest(rootState.user.email, process.env.VUE_APP_KOBP);
                const { data } = await passthrough(request);
                commit('SET_TEMPLATE_TAGS_SUCCESS', data);
            } catch (e) {
                commit('SET_TEMPLATE_TAGS_ERROR', ['Unexpected error during fetch of Template tags']);
                console.error(e);
            }
        },
        async editTemplate({commit, state, dispatch}, {waypointId, template, redirectAfterCreate}) {
            const id = template.TemplateID;

            try {
                commit('EDIT_TEMPLATE_LOADING', id);
                const request = getTemplateEditRequest(template, waypointId);
                const {status, data} = await passthrough(request);
                const { TemplateID } = data;
                if (status === 200) {
                    commit('EDIT_TEMPLATE_SUCCESS', {id, template: data});
                    dispatch('setToast', {
                        type: 'success',
                        message: 'Template edited successfully',
                    }, {
                        root: true,
                    });
                    if (template.TemplateID !== TemplateID && redirectAfterCreate) {
                        await router.push({
                            name: 'messaging-edit',
                            params: {
                                waypointID: waypointId,
                                templateID: TemplateID,
                            },
                        });
                    }
                    return { isSucceeded: true, templateID: TemplateID };
                } else {
                    commit('EDIT_TEMPLATE_ERROR', {id, errors: [editTemplateErrorMessage]});
                    dispatch('setToast', {
                        type: 'error',
                        message: editTemplateErrorMessage,
                    }, {root: true});
                    console.error(status, data);
                    return { isSucceeded: false };
                }
            } catch (e) {
                commit('EDIT_TEMPLATE_ERROR', {id, errors: [editTemplateErrorMessage]});
                dispatch('setToast', {
                    type: 'error',
                    message: editTemplateErrorMessage,
                }, {root: true});
                console.error(e);
                return { isSucceeded: false };
            }
        },
        async batchEditTemplates({commit, state, dispatch}, {waypointId, templates, redirectAfterCreate}) {
            templates.forEach(x => {
                commit('EDIT_TEMPLATE_LOADING', x.TemplateID);
            });

            const requests = templates
                .map(x => [x, getTemplateEditRequest(x, waypointId)])
                .map(([x, request]) => passthrough(request)
                    .then(r => {
                        const {status, data} = r;
                        if (status === 200) {
                            commit('EDIT_TEMPLATE_SUCCESS', {id: x.TemplateID, template: data});
                            return { isSucceeded: true, data };
                        } else {
                            commit('EDIT_TEMPLATE_ERROR', {id: x.TemplateID, errors: ['Unexpected error occured']});
                            return { isSucceeded: false };
                        }
                    }).catch(e => {
                        commit('EDIT_TEMPLATE_ERROR', {id: x.TemplateID, errors: [e.toString()]});
                        return { isSucceeded: false };
                    })
                );

            const results = await Promise.all(requests);
            const succeededCount =  results.filter(x => x.isSucceeded).length;
            const failedCount = results.length - succeededCount;
            if (failedCount > 0) {
                dispatch('setToast', {
                    type: 'error',
                    message: `Unexpected error occurred during saving templates, ${failedCount} out of ${results.length} failed`,
                }, {root: true});
                return { isSucceeded: false };
            } else {
                dispatch('setToast', {
                    type: 'success',
                    message: `Successfully applied changes to ${results.length} message templates`,
                }, {root: true});
                if (redirectAfterCreate) {
                    const templateIds = results.map(x => x.data.TemplateID);
                    return { isSucceeded: true, templateIds };
                }
            }
        },
        async resetTemplate({commit, state, dispatch}, { templateID: id, languageCode, waypointId }) {
            try {
                commit('DELETE_TEMPLATE_LOADING', {id});
                const request = DeleteMessageTemplateRequest(id, process.env.VUE_APP_KOBP, languageCode);
                const {status, data} = await passthrough(request);
                if (status === 200) {
                    commit('DELETE_TEMPLATE_SUCCESS', {id});
                    dispatch('setToast', {
                        type: 'success',
                        message: `Successfully restored template`,
                    }, {root: true});
                } else {
                    commit('DELETE_TEMPLATE_ERROR', {id, errors: [e.toString()]});
                    dispatch('setToast', {
                        type: 'error',
                        message: `Unexpected error occurred during restore of the template`,
                    }, {root: true});
                }
            } catch (e) {
                commit('DELETE_TEMPLATE_ERROR', {id, errors: [e.toString()]});
                dispatch('setToast', {
                    type: 'error',
                    message: `Unexpected error occurred during restore of the template`,
                }, {root: true});
            }
        },
        async bulkResetTemplates({commit, state, dispatch}, { templates }) {
            const templatesToRestore = templates.filter(x => x && (!x.UsingDefault || x.WaypointID));
            templatesToRestore.forEach(x => {
                commit('DELETE_TEMPLATE_LOADING', {id: x.TemplateID});
            });

            const requests = templatesToRestore
                .map(x => [x, DeleteMessageTemplateRequest(x.TemplateID, process.env.VUE_APP_KOBP, x.LanguageCode)])
                .map(([x, request]) => passthrough(request)
                    .then(r => {
                        const {status, data} = r;
                        if (status === 200) {
                            commit('DELETE_TEMPLATE_SUCCESS', {id: x.TemplateID});
                            return true;
                        } else {
                            commit('DELETE_TEMPLATE_ERROR', {id: x.TemplateID, errors: ['Unexpected error occurred']});
                            return false;
                        }
                    }).catch(e => {
                        commit('DELETE_TEMPLATE_ERROR', {id: x.TemplateID, errors: [e.toString()]});
                        return false;
                    })
                );

            const results = await Promise.all(requests);
            const succeededCount =  results.filter(x => x).length;
            const failedCount = results.length - succeededCount;
            if (failedCount > 0) {
                dispatch('setToast', {
                    type: 'error',
                    message: `Unexpected error occurred during restoring templates, ${failedCount} out of ${results.length} failed`,
                }, {root: true});
            } else {
                dispatch('setToast', {
                    type: 'success',
                    message: `Successfully restored ${results.length} non-default message templates`,
                }, {root: true});
            }
        },
    },
    getters: {
        templateMessages: state => {
            return state.templateMessages
        },
        templateMetadataStatus: state => state.templateMetadata.status,
        platformTypes: state => state.templateMetadata.data.platformTypes,
        eventTypes: state => state.templateMetadata.data.eventTypes,
        orderTypes: state => state.templateMetadata.data.orderTypes,
        customerSmsTemplatesStatus: state => state.customerSmsTemplates.status,
        customerSmsTemplates: state => state.customerSmsTemplates.data,
        hasTemplatesLoaded: state => state.customerSmsTemplates.status === OperationStatus.Succeeded,
        templateById: state => id => state.customerSmsTemplates.status === OperationStatus.Succeeded
            ? state.customerSmsTemplates.data.find(x => x.TemplateID === id)
            : {
                SubjectTemplate: null,
                MessageBodyTemplate: null,
            },
        eventLabelById: state => id => {
            if (state.templateMetadata.status !== OperationStatus.Succeeded || typeof id === "undefined") {
                return id.toString();
            }

            return getLabelById(state.templateMetadata.data.eventTypes, id);
        },
        platformLabelById: state => id => {
            if (state.templateMetadata.status !== OperationStatus.Succeeded || typeof id === "undefined") {
                return id.toString();
            }

            return getLabelById(state.templateMetadata.data.platformTypes, id);
        },
        orderTypeLabelById: state => id => {
            if (state.templateMetadata.status !== OperationStatus.Succeeded || typeof id === "undefined") {
                return id.toString();
            }

            return getLabelById(state.templateMetadata.data.orderTypes, id);
        },
        templateTagsStatus: state => state.templateTags.status,
        templateTags: state => state.templateTags.data,
        templateEditStatus: state => id => state.editStatus[id] ? state.editStatus[id].status : OperationStatus.Unknown,
        templateDeleteStatus: state => id => state.deleteStatus[id] ? state.deleteStatus[id].status : OperationStatus.Unknown,
    }
}

const GetTemplateMessagesRequest = (storeWaypointID) => ({
    url: 'CursusHub_GetCustomerSMSMessageTemplatesByWaypoint',
    method: 'GET',
    qs: {
        storeWaypointID,
    }
})

const GetTemplateMetadataRequest = () => ({
    url: 'CursusHub_GetMessageTemplateMetadata',
    method: 'GET',
});

const GetMessageTemplateTagsRequest = (email, kobp) => ({
    url: 'CursusHub_GetMessageTemplateTags',
    method: 'GET',
    qs: {
        email, kobp
    }
});

const EditMessageTemplateRequest = (storeWaypointID, kobp, body) => ({
    url: 'CursusHub_UpdateMessageTemplate',
    method: 'POST',
    json: true,
    qs: {
        storeWaypointID, kobp
    },
    body,
});

const CreateMessageTemplateRequest = (storeWaypointID, kobp, body) => ({
    url: 'CursusHub_CreateMessageTemplate',
    method: 'POST',
    json: true,
    qs: {
        storeWaypointID, kobp
    },
    body: {
        ...body,
        TemplateID: '',
        UsingDefault: false,
        WaypointID: storeWaypointID,
    },
});

const DeleteMessageTemplateRequest = (templateID, kobp, languageCode) => ({
    url: 'CursusHub_DeleteMessageTemplate',
    method: 'POST',
    qs: {
        templateID, kobp, languageCode,
    },
});
