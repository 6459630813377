const modTransform = require('./mod.js')

const mapGeneralModGroupFields = modGroup => {
    if (!modGroup.inventoryOptions && !modGroup.inventoryChoices) {
        return modGroup
    }

    const mods = modGroup.inventoryOptions || modGroup.inventoryChoices
    const processedMods = mods
        .map(modTransform.commit)
    var result = Object.assign(modGroup, {
        available: modGroup.optionGroupAvailable == undefined ? modGroup.choiceGroupAvailable : modGroup.optionGroupAvailable,
        displayOrder: modGroup.optionGroupDisplayOrder == undefined ? parseInt(modGroup.choiceGroupDisplayOrder): parseInt(modGroup.optionGroupDisplayOrder) + 100,
        endTime: modGroup.optionGroupEndTime || modGroup.choiceGroupEndTime,
        startTime: modGroup.optionGroupStartTime || modGroup.choiceGroupStartTime,
        title: modGroup.optionGroupName || modGroup.choiceGroupName,
        id: modGroup.optionID || modGroup.choiceID,
        selection: modGroup.optionSelection || modGroup.choiceSelection,
        children: processedMods,
    })
    return result;
}

const removeSpecficModGroupFields = modGroup => {
    delete modGroup.optionGroupAvailable
    delete modGroup.choiceGroupAvailable
    delete modGroup.optionGroupDisplayOrder
    delete modGroup.choiceGroupDisplayOrder
    delete modGroup.optionGroupEndTime
    delete modGroup.choiceGroupEndTime
    delete modGroup.optionGroupStartTime
    delete modGroup.choiceGroupStartTime
    delete modGroup.optionGroupName
    delete modGroup.choiceGroupName
    delete modGroup.optionID
    delete modGroup.choiceID
    delete modGroup.optionSelection
    delete modGroup.choiceSelection
    delete modGroup.inventoryOptions
    delete modGroup.inventoryChoices

    return modGroup
}

const mapOptionSpecificModGroupFields = modGroup => {
    const children = modGroup.children ? modGroup.children.map(modTransform.revert('option')) : []

    return Object.assign({
        optionGroupAvailable: modGroup.available,
        optionGroupDisplayOrder: modGroup.displayOrder || 1,
        optionGroupEndTime: modGroup.endTime,
        optionGroupStartTime: modGroup.startTime,
        optionGroupName: modGroup.title,
        optionID: modGroup.id,
        optionGroupSelection: modGroup.selection || 0,
        inventoryOptions: children.map(el => {
            return {
                ...el,
                optionAvailable: modGroup.available
            }
        }),
    })
}

const mapChoiceSpecificModGroupFields = modGroup => {
    const children = modGroup.children ? modGroup.children.map(modTransform.revert('choice')) : []

    return Object.assign({
        choiceGroupAvailable: modGroup.available,
        choiceGroupDisplayOrder: modGroup.displayOrder || 1,
        choiceGroupEndTime: modGroup.endTime,
        choiceGroupStartTime: modGroup.startTime,
        choiceGroupName: modGroup.title,
        choiceID: modGroup.id,
        choiceGroupSelection: modGroup.selection || 0,
        inventoryChoices: children.map(el => {
            return {
                ...el,
                choiceAvailable: modGroup.available
            }
        }),
    })
}

const removeGeneralModGroupFields = modGroup => {
    delete modGroup.available
    delete modGroup.displayOrder
    delete modGroup.endTime
    delete modGroup.startTime
    delete modGroup.title
    delete modGroup.id
    delete modGroup.selection
    delete modGroup.children

    return modGroup
}

exports.commit = modGroup => {
    const group = removeSpecficModGroupFields(mapGeneralModGroupFields(modGroup))

    if (group.children.length) {
        group.pos_modifierGroupID = group.children[0].productID
    }
    return group
}

exports.revert = modGroup => {
    if (modGroup.children && modGroup.children.length) {
        modGroup.pos_modifierGroupID = modGroup.children[0].pos_modifierID
    }

    const resolvedModGroup = modGroup.required ?
        mapChoiceSpecificModGroupFields(modGroup) :
        mapOptionSpecificModGroupFields(modGroup)

    const processedModGroup = removeGeneralModGroupFields(resolvedModGroup)
    return Object.assign(processedModGroup, {
        pos_modifierGroupID: modGroup.pos_modifierGroupID || '',
    })
}
