import { passthrough, authHttp } from '../http'

export default {
    namespaced: true,
    state: {
        uploadedImages: {}

    },
    mutations: {
        ADD_UPLOADED_IMAGE: (state, payload) => {
            state.uploadedImages[payload.fileName] = payload.imageBase64
        },
    },
    actions: {
        addUploadedImageForProduct: ({ commit }, payload) => {
            commit('ADD_UPLOADED_IMAGE', payload)
        },
        removeUploadedImageForProduct: ({ commit }, imageName) => {
            commit('REMOVE_UPLOADED_IMAGE', imageName)
        },

        uploadImageV2: ({ commit }, payloadArray) => {
            var promises = []
            commit('SET_LOADING', true, { root: true })

            for (let payload of payloadArray) {
                promises.push(authHttp.post('/api/products/imageV2', payload).then((res) => {
                    commit('ADD_UPLOADED_IMAGE', {
                        fileName: payload.imageName,
                        imageBase64: payload.imageBase64,
                    })
                }))
            }

            return Promise.all(promises).finally(() => {
                commit('SET_LOADING', false, { root: true })
            })
        },
        setImageV2: ({ commit, dispatch }, payload) => {
            var promises = []
            commit('SET_LOADING', true, { root: true })

            let { storeWaypointID, airportIdent, imageTypeID } = payload

            for (let image of payload.images) {
                let request = SetImagesRequest(storeWaypointID, image.inventoryItemID, image.inventoryItemSubID, image.imageName, imageTypeID, airportIdent)
                promises.push(passthrough(request).then(() => {
                    return true
                }))
            }

            return Promise.all(promises).finally(() => {
                commit('SET_LOADING', false, { root: true })
            })
        },
        uploadStoreImage: ({ commit}, payload) => {
            commit('SET_LOADING', true, { root: true })
            return authHttp.post('/api/store/imageV2', payload).then((res) => {
                commit('ADD_UPLOADED_IMAGE', {
                    fileName: payload.fileName,
                    imageBase64: payload.imageBase64,
                })
            }).finally(() => {
                commit('SET_LOADING', false, { root: true })
            })
        },
        uploadCategoryImage: ({ commit}, payload) => {
            commit('SET_LOADING', true, { root: true })
            return authHttp.post('/api/category/imageV2', payload).then((res) => {
                commit('ADD_UPLOADED_IMAGE', {
                    fileName: payload.imageName,
                    imageBase64: payload.imageBase64,
                })
            }).finally(() => {
                commit('SET_LOADING', false, { root: true })
            })
        }
    },
}
//string email, string kobp, long storeWaypointID, long inventoryItemID, long inventoryItemSubID, string imageName, int imageTypeID, string airportIdent)
let SetImagesRequest = (storeWaypointID, inventoryItemID, inventoryItemSubID, imageName, imageTypeID, airportIdent) => {
    return {
        url: 'CursusHub_SetInventoryImageName',
        method: 'GET',
        qs: {
            storeWaypointID,
            inventoryItemID,
            inventoryItemSubID,
            imageName,
            imageTypeID,
            airportIdent
        },
    }
}