import axios from 'axios'
import router from './routes'

const auth = axios.create()

auth.interceptors.request.use(config => {
    const { app } = router

    config.headers.common.Authorization = localStorage.accessToken
    config.params = { email: app.$store.state.user.email }

    return config
}, error => error)

auth.interceptors.response.use(response => response, error => {
    const { response } = error

    if (response.status === 401) {
        const { app } = router

        app.$store.commit('CLEAR_LOADING')
        app.$store.commit('RECIEVE_USER', false)
        app.toast('You are not authorized. Please login again.', 'error')

        localStorage.removeItem('accessToken')
        router.replace({name: 'login'})
    }

    return Promise.reject(response)
})

export const http = axios
export const authHttp = auth

export const passthrough = (requestObject) => {
    return auth.post('/api/passthrough', requestObject)
}

export const configService = (requestObject) => {
    return auth.post('/api/configservice/passthrough', requestObject)
}