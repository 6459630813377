export const Permissions = {
    MENU_MODIFICATION: "Menu Modification",
    MODIFIERS: "Modifiers",
    CALORIC_UPC: "Caloric / UPC Information",
    IMAGES: "Images",
    UPSELL: "Upsell",
    TABLE_NUMBERS: "Table Numbers",
    MENU_SYNC: "Menu Sync"
}

export const AccessAreas = {
    MENU_MODIFICATION: "MENU_MODIFICATION",
    MODIFIERS: "MODIFIERS",
    CALORIC_UPC: "CALORIC_UPC",
    IMAGES: "IMAGES",
    UPSELL: "UPSELL",
    TABLE_NUMBERS: "TABLE_NUMBERS",
    MENU_SYNC: "MENU_SYNC"
}

export const Access = {
    VIEW: "View",
    NONE: "NoAccess",
    EDIT: "Edit",
}

export const IsPermissionedAsGrabber = (user) => {
    return user.email != undefined && (user.email.includes('@getgrab.com') || user.email.includes('@servy.us'))
}

const HasPermissions = (user, storeWaypointID) => {
    return user.storeAccess != undefined
}

const GetPermission = (user, storeWaypointID, key) => {
    if (IsPermissionedAsGrabber(user)) return Access.EDIT
    if (!HasPermissions(user)) return false

    let storeAccess = user.storeAccess.find(el => el.storeWaypointID == storeWaypointID)
    if (storeAccess == undefined) return false
    let permission = storeAccess.permissions[key]
    if (permission == undefined) return false

    return permission
}

export const UserCanView = (user, storeWaypointID, key) => {
    let permission = GetPermission(user, storeWaypointID, key)

    return permission == Access.VIEW || permission == Access.EDIT

}

export const UserCanEdit = (user, storeWaypointID, key) => {
    let permission = GetPermission(user, storeWaypointID, key)

    return permission == Access.EDIT

}

export const AccessHelper = {
    userCanView: UserCanView,
    userCanEdit: UserCanEdit,
    ...AccessAreas
}

