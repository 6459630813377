import Vue from 'vue'
import { passthrough, configService } from '../http'
import clone from 'clone'

export default {
    namespaced: true,
    state: {
        storeWaypointID: undefined,
        bandaidConfigs: {},
        bandaidConfigMetadata: {},
        configs: {},
        configMetadata: {},
        bandaidStyles: {},
        bandaidStyleData: [],
        resources: {},
        svgAssets: {},
        assetsMetadata: {},
        styles: {},
        styleMetadata: {}
    },
    mutations: {
        SET_STORE_WAYPOINTID: (state, storeWaypointID) => {
            if (state.storeWaypointID == storeWaypointID) return
            state.storeWaypointID = storeWaypointID
            state.configs = {}
            state.styles = {}
            state.stylesMetadata = {}
            state.resources = {}
        },
        SET_BANDAID_CONFIGS: (state, { storeWaypointID, configs }) => {
            var processed = {}
            for (const c of configs) {
                if (c.DataCenter == 'STAGE')
                    processed.stage = c.store
                else if (c.DataCenter == 'PROD_US')
                    processed.prod = c.store
            }
            if (state.storeWaypointID == storeWaypointID)
                Vue.set(state, 'bandaidConfigs', processed)
        },
        SET_STAGE_BANDAID_CONFIG: (state, { storeWaypointID, config }) => {
            if (state.storeWaypointID == storeWaypointID)
                Vue.set(state.bandaidConfigs, 'stage', config)
        },
        SET_CONFIGS: (state, { storeWaypointID, configs, env}) => {
            if (state.storeWaypointID == storeWaypointID)
                Vue.set(state.configs, env, configs)
        },
        SET_SVG_ASSETS: (state, { storeWaypointID, assets, env}) => {
            if (state.storeWaypointID == storeWaypointID)
                Vue.set(state.svgAssets, env, assets)
        },
        SET_BANDAID_STYLES: (state, { storeWaypointID, styles }) => {
            var processed = {}
            var metadata = []
            for (const s of styles.Styles) {
                var value = s.StyleValue == "" || s.StyleValue == null || s.StyleValue === undefined ? s.DefaultValue : s.StyleValue;


                if (value == 'transparent') value = "#FFFFFF00"
                if (value.indexOf("url") == -1) {
                    processed[s.StyleName] = value
                    metadata.push({
                        name: s.StyleName.split('-').map(el => el.charAt(0).toUpperCase() + el.slice(1)).join(" "),
                        type: s.StyleTypeName,
                        doc: s.StyleDescription,
                        platforms: ["ALL"],
                        key: s.StyleName,
                        original: s
                    })
                }

            }
            if (state.storeWaypointID == storeWaypointID) {
                Vue.set(state, 'bandaidStyles', processed)
                Vue.set(state, 'bandaidStyleData', metadata)
            }
        },
        SET_STYLES: (state, { storeWaypointID, styles, env }) => {
            const a = {}
            for (const key in styles) {
                if (Object.hasOwnProperty.call(styles, key)) {
                    if (key == "id") continue;
                    var value = styles[key].color;
                    if (!value) console.log(key)
                    if (value == 'transparent') value = "#FFFFFF00"
                    if (value.indexOf("url") != -1) value = "#FFFFFF00"
                    a[key] = value
                    
                }
            }
            if (state.storeWaypointID == storeWaypointID) {
                console.log(env)
                Vue.set(state.styles, env, a)
            }
        },
        SET_RESOURCES: (state, { languageCode, resources, env }) => {
            delete resources.id
            if (state.resources[env] == undefined) state.resources[env] = {}
            const a = clone(state.resources)
            a[env][languageCode] = resources
            Vue.set(state, 'resources', a)
        },
        SET_BANDAID_CONFIG_METADATA: (state, configMetadata) => {
            state.bandaidConfigMetadata = configMetadata
        },
        SET_ASSETS_METADATA: (state, metadata) => {
            state.assetsMetadata = metadata
        },
        SET_CONFIG_METADATA: (state, configMetadata) => {
            state.configMetadata = configMetadata
        },
        SET_STYLE_METADATA: (state, styleMetadata) => {
            state.styleMetadata = styleMetadata
        }
    },
    actions: {
        getConfigs: ({ commit }, storeWaypointID) => {
            commit('SET_STORE_WAYPOINTID', storeWaypointID)

            let envs = ['stage', 'prod']

            return Promise.all(envs.map(env => {
                return configService(ConfigRequest(storeWaypointID, env)).then(res => {
                    return { key: env, data: res.data}
                })            
            })).then((results) => {
                for (const o of results) {
                    commit('SET_CONFIGS', {storeWaypointID, configs: o.data, env: o.key})
                }
            })
        },
        getSVGAssets: ({ commit }, storeWaypointID) => {
            commit('SET_STORE_WAYPOINTID', storeWaypointID)

            let envs = ['stage', 'prod']

            return Promise.all(envs.map(env => {
                return configService(SVGAssetsRequest(storeWaypointID, env)).then(res => {
                    return { key: env, data: res.data}
                })            
            })).then((results) => {
                for (const o of results) {
                    commit('SET_SVG_ASSETS', {storeWaypointID, assets: o.data, env: o.key})
                }
            })
        },
        getBandaidConfigs: ({ commit }, storeWaypointID) => {
            commit('SET_STORE_WAYPOINTID', storeWaypointID)

            let request = BandaidConfigRequest(storeWaypointID)
            return passthrough(request).then(res => {
                commit('SET_BANDAID_CONFIGS', { storeWaypointID, configs: res.data })
                return true
            })
        },
        saveBandaidConfig: ({ commit }, { storeWaypointID, config }) => {
            commit('SET_LOADING', true, { root: true })

            let request = SaveBandaidConfigRequest(storeWaypointID, config)
            return passthrough(request).then(res => {
                commit('SET_STAGE_BANDAID_CONFIG', { storeWaypointID, config: res.data.store })
                return true
            }).finally(() => {
                commit('SET_LOADING', false, { root: true })
            })
        },
        saveConfigs: ({ commit }, {storeWaypointID, config}) => {
            commit('SET_LOADING', true, { root: true })

            let request = SaveConfigRequest(storeWaypointID, config)
            return configService(request).then(res => {
                return true
            }).finally(() => {
                commit('SET_LOADING', false, { root: true })
            })
        },
        saveSVGAssets: ({ commit }, {storeWaypointID, assets}) => {
            commit('SET_LOADING', true, { root: true })

            let request = SaveSVGAssetsRequest(storeWaypointID, assets)
            return configService(request).then(res => {
                return true
            }).finally(() => {
                commit('SET_LOADING', false, { root: true })
            })
        },
        publishConfigs: ({ state, commit, dispatch }, storeWaypointID) => {
            if (storeWaypointID != state.storeWaypointID) return
            commit('SET_LOADING', true, { root: true })

            let request = PublishConfigRequest(storeWaypointID, state.configs.stage)
            return configService(request).then(res => {
                dispatch('getConfigs', storeWaypointID);
                return true
            }).finally(() => {
                commit('SET_LOADING', false, { root: true })
            })
        },
        publishResources: ({ state, commit, dispatch }, {storeWaypointID, languageCode}) => {
            if (storeWaypointID != state.storeWaypointID) return
            commit('SET_LOADING', true, { root: true })

            let request = PublishResourcesRequest(storeWaypointID, languageCode)
            return configService(request).then(res => {
                dispatch('getResources', {storeWaypointID, languageCode});
                return true
            }).finally(() => {
                commit('SET_LOADING', false, { root: true })
            })
        },
        publishSVGAssets: ({ state, commit, dispatch }, storeWaypointID) => {
            if (storeWaypointID != state.storeWaypointID) return
            commit('SET_LOADING', true, { root: true })

            let request = PublishAssetsRequest(storeWaypointID)
            return configService(request).then(res => {
                dispatch('getSVGAssets', storeWaypointID);
                return true
            }).finally(() => {
                commit('SET_LOADING', false, { root: true })
            })
        },
        publishBandaidConfigs: ({ state, commit, dispatch }, storeWaypointID) => {
            return
            if (storeWaypointID != state.storeWaypointID) return
            commit('SET_LOADING', true, { root: true })

            let request = PublishBandaidConfigRequest(storeWaypointID, state.bandaidConfigs.stage)
            return passthrough(request).then(res => {
                dispatch('getBandaidConfigs', storeWaypointID);
                return true
            }).finally(() => {
                commit('SET_LOADING', false, { root: true })
            })
        },
        getBandaidStyles: ({ commit }, storeWaypointID) => {
            commit('SET_STORE_WAYPOINTID', storeWaypointID)

            let request = BandaidStylesRequest(storeWaypointID)
            return passthrough(request).then(res => {
                commit('SET_BANDAID_STYLES', { storeWaypointID, styles: res.data })
                return true
            })
        },
        saveStyles: ({ commit, dispatch }, { storeWaypointID, styles , bandaid }) => {
            commit('SET_LOADING', true, { root: true })

            const processed = {}
            for (const key in styles) {
                if (Object.hasOwnProperty.call(styles, key)) {
                    const element = styles[key];
                    processed[key] = { color: element}
                }
            }
            return Promise.all([
                passthrough(SaveBandaidStylesRequest(storeWaypointID, bandaid)).then(res => {
                    commit('SET_BANDAID_STYLES', { storeWaypointID, styles: res.data })
                    return true
                }),
                configService(SaveStylesRequest(storeWaypointID, processed)).then(res => {
                    dispatch('getStyles', storeWaypointID);
                    return true
                })
            ]).finally(() => {
                commit('SET_LOADING', false, { root: true })
            })
        },
        publishStyles: ({ commit, state, dispatch }, storeWaypointID) => {
            if (storeWaypointID != state.storeWaypointID) return
            commit('SET_LOADING', true, { root: true })

            let request = PublishStylesRequest(storeWaypointID)
            return configService(request).then(res => {
                dispatch('getStyles', storeWaypointID);
                return true
            }).finally(() => {
                commit('SET_LOADING', false, { root: true })
            })
        },
        getStyles: ({ commit, dispatch }, storeWaypointID) => {
            commit('SET_STORE_WAYPOINTID', storeWaypointID)

            let envs = ['stage', 'prod']

            return Promise.all(envs.map(env => {
                return configService(StylesRequest(storeWaypointID, env)).then(res => {
                    return { key: env, data: res.data}
                })            
            })).then((results) => {
                for (const o of results) {
                    console.log(o)
                    commit('SET_STYLES', {storeWaypointID, styles: o.data, env: o.key})
                }
                dispatch('getBandaidStyles', storeWaypointID);
            })
        },
        getResources: ({ commit }, { storeWaypointID, languageCode }) => {
            commit('SET_STORE_WAYPOINTID', storeWaypointID)

            // // let envs = ['1', '2'] // stage, prod
            // let envs = ['1'] // stage
            let envs = ['stage', 'prod']

            return Promise.all(envs.map(env => {
                return configService(ResourcesRequest(storeWaypointID, env, languageCode)).then(res => {
                    return { key: env, data: res.data}
                })            
            })).then((results) => {
                for (const o of results) {
                    commit('SET_RESOURCES', {storeWaypointID, resources: o.data, env: o.key, languageCode: languageCode})
                }
            })
        },
        saveResources: ({ commit, dispatch }, { storeWaypointID, languageCode, resources }) => {
            commit('SET_LOADING', true, { root: true })

            let request = SaveResourcesRequest(storeWaypointID, languageCode, resources)
            return configService(request).then(res => {
                dispatch('getResources', {storeWaypointID, languageCode});
                return true
            }).finally(() => {
                commit('SET_LOADING', false, { root: true })
            })
        },
        getBandaidConfigMetadata: ({ commit, state }) => {
            if (Object.keys(state.bandaidConfigMetadata).length > 0) return
            let request = BandaidConfigMetadataRequest()
            return configService(request).then(res => {
                commit('SET_BANDAID_CONFIG_METADATA', res.data)
                return true
            })
        },
        getAssetsMetadata: ({ commit, state }) => {
            if (Object.keys(state.assetsMetadata).length > 0) return
            let request = AssetsMetadataRequest()
            return configService(request).then(res => {
                commit('SET_ASSETS_METADATA', res.data)
                return true
            })
        },
        getConfigMetadata: ({ commit, state }) => {
            if (Object.keys(state.configMetadata).length > 0) return
            let request = ConfigMetadataRequest()
            return configService(request).then(res => {
                commit('SET_CONFIG_METADATA', res.data)
                return true
            })
        },
        getStyleMetadata: ({ commit, state }) => {
            if (Object.keys(state.styleMetadata).length > 0) return
            let request = StyleMetadataRequest()
            return configService(request).then(res => {
                commit('SET_STYLE_METADATA', res.data)
                return true
            })
        }
    },
    getters: {
        bandaidConfigs: state => {
            return {
                ...state.bandaidConfigs,
                storeWaypointID: state.storeWaypointID
            }
        },
        configs: state => {
            return {
                ...state.configs,
                storeWaypointID: state.storeWaypointID
            } 
        },
        styles: state => {
            return {
                ...state.styles,
                storeWaypointID: state.storeWaypointID
            }
        },
        resources: state => {
            return {
                ...state.resources,
                storeWaypointID: state.storeWaypointID
            }
        },
        bandaidConfigMetadata: state => {
            return state.bandaidConfigMetadata
        },
        configMetadata: state => {
            return state.configMetadata
        },
        bandaidStyleData: state => {
            return state.bandaidStyleData
        },
        styleMetadata: state => {
            return state.styleMetadata
        },
        configStoreWaypointID: state => {
            return state.storeWaypointID
        },
        svgAssets: state => {
            return {
                ...state.svgAssets,
                storeWaypointID: state.storeWaypointID
            }
        },
        svgAssetsMetadata: state => {
            return state.assetsMetadata
        }
    }
}


let BandaidConfigRequest = (storeWaypointID) => {
    return {
        url: 'grabTableTop_GetOATConfigAllDCs',
        method: 'GET',
        qs: {
            waypointID: storeWaypointID,
        },
    }
}

let BandaidStylesRequest = (storeWaypointID) => {
    return {
        url: 'Cursus_TableTop_GetStylesForWaypoint',
        method: 'GET',
        qs: {
            waypointID: storeWaypointID,
        },
    }
}

let StylesRequest = (storeWaypointID, env) => {
    return {
        url: `waypoint/${storeWaypointID}/styles/${env}`,
        method: 'GET',
    }
}

let ConfigRequest = (storeWaypointID, env) => {
    return {
        // url: `waypoint/${storeWaypointID}/configs/${env}`,
        url: `OATAdmin/GetLanguages`,
        method: 'GET',
        qs: {
            waypoint: storeWaypointID,
            environment: env
        }
    }
}

let SVGAssetsRequest = (storeWaypointID, env) => {
    return {
        url: `waypoint/${storeWaypointID}/assets/${env}`,
        method: 'GET',
    }
}

let ResourcesRequest = (storeWaypointID, env, languageCode) => {
    return {
        url: `OATAdmin/GetResources`,
        method: 'GET',
        qs: {
            waypoint: storeWaypointID,
            environment: env,
            language: languageCode
        }
    }
}
let BandaidConfigMetadataRequest = () => {
    return {
        url: 'metadata/BandaidConfigs',
        method: 'GET',
    }
}
let AssetsMetadataRequest = () => {
    return {
        url: 'metadata/SVGAssets',
        method: 'GET',
    }
}
let ConfigMetadataRequest = () => {
    return {
        url: 'metadata/Configs',
        method: 'GET',
    }
}
let StyleMetadataRequest = () => {
    return {
        url: 'metadata/Styles',
        method: 'GET',
    }
}

let SaveResourcesRequest = (storeWaypointID, languageCode, resources) => {
    return {
        // url: `waypoint/${storeWaypointID}/resources/stage`,
        url: `OATAdmin/SaveResourceToStage`,
        qs: {
            waypoint: storeWaypointID,
            language: languageCode
        },
        body: resources,
        method: 'PUT',
    }
}

let SaveConfigRequest = (storeWaypointID, config) => {
    return {
        url: `waypoint/${storeWaypointID}/configs/stage`,
        body: config,
        method: 'PUT',
    }
}

let SaveSVGAssetsRequest = (storeWaypointID, assets) => {
    return {
        url: `waypoint/${storeWaypointID}/assets/stage`,
        body: assets,
        method: 'PUT',
    }
}

let PublishConfigRequest = (storeWaypointID) => {
    return {
        url: `waypoint/${storeWaypointID}/configs/copy/prod`,
        qs: {
            sourceEnv: "stage"
        },
        method: 'PUT',
    }
}

let PublishResourcesRequest = (storeWaypointID, languageCode) => {
    return {
        // url: `waypoint/${storeWaypointID}/resources/copy/prod`,
        // qs: {
        //     sourceEnv: "stage"
        // },
        url: `OATAdmin/PublishResourcesToProd`,
        qs: {
            waypoint: storeWaypointID,
            language: languageCode
        },
        method: 'PUT',
    }
}

let PublishStylesRequest = (storeWaypointID) => {
    return {
        url: `waypoint/${storeWaypointID}/styles/copy/prod`,
        qs: {
            sourceEnv: "stage"
        },
        method: 'PUT',
    }
}
let PublishAssetsRequest = (storeWaypointID) => {
    return {
        url: `waypoint/${storeWaypointID}/assets/copy/prod`,
        qs: {
            sourceEnv: "stage"
        },
        method: 'PUT',
    }
}


let SaveBandaidConfigRequest = (waypointID, body) => {
    return {
        method: 'POST',
        url: 'Cursus_TableTop_SaveConfigurationForWaypoint',
        qs: {
            waypointID,
        },
        body
    }
}

let SaveBandaidStylesRequest = (waypointID, body) => {
    return {
        method: 'POST',
        url: 'Cursus_TableTop_SaveStylesForWaypoint',
        qs: {
            waypointID,
        },
        body
    }
}
let SaveStylesRequest = (storeWaypointID, styles) => {
    return {
        url: `waypoint/${storeWaypointID}/styles/stage`,
        body: styles,
        method: 'PUT',
    }
}

let PublishBandaidConfigRequest = (waypointID, body) => {
    return {
        method: 'POST',
        url: 'Cursus_TableTop_PublishConfiguration',
        qs: {
            waypointID,
        },
        body
    }
}
